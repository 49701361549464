<div class="page-content">

    <div class="page-wrapper">

        <h1>Contact Us</h1>
        <p class="message">Have a project in mind? Get in touch today.</p>

        <form class="account-login-form" [formGroup]="contactUsForm" (ngSubmit)="initSubmit()">
            <div class="field-wrapper">
                <label for="fullName">First & Last Name<span>*</span> </label>
                <input id="fullName" type="fullName" placeholder="First & Last Name" formControlName="fullName">

                @if (contactUsForm.controls.fullName.dirty && contactUsForm.controls.fullName.errors?.['required']) {
                    <p class="error-message">First name is required</p>
                }
            </div>

            <div class="field-wrapper">
                <label for="email">Email Address<span>*</span> </label>
                <input id="email" type="email" placeholder="Email Address" formControlName="email">

                @if (contactUsForm.controls.email.dirty && contactUsForm.controls.email.errors?.['required']) {
                    <p class="error-message">Email Address is required</p>
                }
                @if (contactUsForm.touched && contactUsForm.controls.email.errors?.['email']) {
                    <p class="error-message">Invalid Email Address</p>
                }
            </div>
            <div class="field-wrapper">
                <label for="phone">Phone Number </label>
                <input id="phone" maxlength="14" type="phone" placeholder="Phone Number" formControlName="phone" (change)="updatePhoneDisplayInForm()" (keyup)="updatePhoneDisplayInForm()">
            </div>

            <!-- Interests -->
            <div class="field-wrapper">
                <p class="label">I'm interested in...<span>*</span> </p>
                  <label class="check-group" for="interestedInWebDesign">
                    <input class="form-control" id="interestedInWebDesign" type="checkbox" formControlName="interestedInWebDesign" />
                    <span class="checkbox">
                        <span></span>
                    </span>
                    <p class="label">Website Design</p>
                </label>
                  <label class="check-group" for="interestedInWebDevelopment">
                    <input class="form-control" id="interestedInWebDevelopment" type="checkbox" formControlName="interestedInWebDevelopment" />
                    <span class="checkbox">
                        <span></span>
                    </span>
                    <p class="label">Website Development</p>
                </label>
                  <label class="check-group" for="interestedInStrategy">
                    <input class="form-control" id="interestedInStrategy" type="checkbox" formControlName="interestedInStrategy" />
                    <span class="checkbox">
                        <span></span>
                    </span>
                    <p class="label">Strategy</p>
                </label>
                  <label class="check-group" for="interestedInBranding">
                    <input class="form-control" id="interestedInBranding" type="checkbox" formControlName="interestedInBranding" />
                    <span class="checkbox">
                        <span></span>
                    </span>
                    <p class="label">Branding</p>
                </label>
                  <label class="check-group" for="interestedInOther">
                    <input class="form-control" id="interestedInOther" type="checkbox" formControlName="interestedInOther" />
                    <span class="checkbox">
                        <span></span>
                    </span>
                    <p class="label">Other</p>
                </label>
            </div>

            @if (contactUsForm.value.interestedInOther) {
                <div class="field-wrapper">
                    <label for="otherInterest">Other interest<span>*</span></label>
                    <input id="otherInterest" type="otherInterest" placeholder="Please describe..." formControlName="otherInterest">
                </div>
            }




            <div class="field-wrapper">
                <label for="websiteURL">Website URL </label>
                <input id="websiteURL" type="websiteURL" placeholder="https://..." formControlName="websiteURL">
            </div>
            <div class="field-wrapper">
                <label for="message">Message<span>*</span> </label>
                <textarea  id="message" type="message" placeholder="What can we help you with?" rows="4" formControlName="message"></textarea>
            </div>


            <div class="btn-container">
                <button class="btn btn-primary"  [ngClass]="{'launch': launch, 'sending' : sending, 'feedback' : showFeedback}" [disabled]="!contactUsForm.valid || !checkForInterestSelection() || launch || sending || showFeedback">

                    @if (!showFeedback) {
                        @if (!sending) {
                            Send
                            <div class="icon-container">
                                <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.15886C4.41438 8.15886 8.02587 4.53761 8.02587 0H12.0259V0.0379376C12.0333 4.569 15.6419 8.18281 20.0517 8.18281V12.1828C16.9591 12.1828 14.15 11.0096 12.0259 9.08403L12.0259 26.0067H8.02587L8.02587 9.06007C5.90178 10.9857 3.09268 12.1589 0 12.1589V8.15886Z" />
                                </svg>
                            </div>
                        } @else {
                            Sending
                        }
                    } @else {
                        {{feedbackMessage}}
                    }

 
                </button>
            </div>
        </form>

    </div>
</div>
