
<div class="page-content">

    <div class="page-wrapper">

        <h1>Privacy Policy</h1>

        <h2>1. Business Overview</h2>
        <p>bouncd ("the Agency") provides professional web design and web development services. This privacy policy outlines how we collect, use, and protect your personal data in connection with our services.</p>
        
        <h2>2. Data Collection</h2>
        <p>We collect personal information such as names, emails, and phone numbers through our contact form. Additionally, we process payments securely through Stripe.</p>
        
        <h2>3. Data Usage and Retention</h2>
        <p>The personal data we collect is used solely to generate new business leads and improve our services. We retain this data permanently unless you request its deletion. We do not share your data with any third parties.</p>
        
        <h2>4. Compliance</h2>
        <p>Our privacy practices comply with the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). We are committed to protecting your privacy and ensuring that your personal data is handled in accordance with these regulations.</p>
        
        <h2>5. User Rights</h2>
        <p>You have the right to access, modify, or delete your personal data at any time. You may also opt-out of our services. To exercise these rights, please contact us via email at <a href="mailto:support@bouncd.com">support&#64;bouncd.com</a>. We typically respond to such requests within 24-48 business hours. Alternatively, you may reach out to us through our general contact form at <a href="https://www.bouncd.com/lets-chat">https://www.bouncd.com/lets-chat</a>.</p>
        
        <h2>6. Security Measures</h2>
        <p>We implement industry-standard security measures, including encryption, authentication, and limited access, to protect your personal data from unauthorized access or disclosure.</p>
        
        <h2>7. Analytics</h2>
        <p>We collect basic website analytics to understand how users interact with our site. This includes data on page views, link clicks, geolocation, and time spent on the site. We do not use cookies to collect this information.</p>
        
        <h2>8. Third-Party Services</h2>
        <p>We process payments through Stripe, a third-party payment processor. For more information on how Stripe handles your personal data, please refer to their privacy policy at <a href="https://stripe.com/legal/stripe-shop">https://stripe.com/legal/stripe-shop</a>.</p>
        
        <h2>Conclusion</h2>
        <p>This policy is intended to provide clarity on how we handle your personal data. If you have any questions or concerns about this privacy policy, please do not hesitate to contact us.</p>

        <div class="divider"></div>

        <div class="address">
            <h2>Mailing Address</h2>
            <p>79 East Main Street <br>
                Suite 406<br>
                Lititz, PA, 17543</p>
            <p>Phone: <a href="tel:8008906476">(800) 890-6476</a></p>
            <p>Email: <a href="mailto:support@bouncd.com">support&#64;bouncd.com</a></p>
        </div>
    </div>
</div>


