import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { DesignComponent } from './pages/services/design/design.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { DevelopmentComponent } from './pages/services/development/development.component';
import { StrategyComponent } from './pages/services/strategy/strategy.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AboutComponent } from './pages/about/about.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'about-us', component: AboutComponent, pathMatch: 'full' },
  { path: 'services/design', component: DesignComponent, pathMatch: 'full' },
  { path: 'services/development', component: DevelopmentComponent, pathMatch: 'full' },
  { path: 'services/strategy', component: StrategyComponent, pathMatch: 'full' },
  { path: 'lets-chat', component: ContactUsComponent, pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: 'external-api', component: ExternalApiComponent, canActivate: [AuthGuard]},
  // 404
  { path: '**', component: HomeComponent , redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
