<div class="page-banner">

    <div class="banner-container">
        <h1>Full-Cycle Web Development Services</h1>
        <p>We will turn your ideas into a fully functional website. We cover every aspect, from front-end development to back-end systems, and ensure a smooth deployment and ongoing support.</p>
    </div>
</div>

<div class="page-content">

    <div class="page-wrapper service-wrapper">

        <div class="sticky-bar" id="sticky-bar">
            <div class="sticky-list" id="sticky-list" [ngClass]="{'fixed' : fixedList, 'absolute' : absoluteList}">
                @for (item of serviceList; track item.id) {
                <p [ngClass]="{'active' : item.active}" (click)="selectServiceItem(item)">{{item.title}}</p>
                }
            </div>
        </div>

        <div class="info-column">
            <div class="info-list">
                @for (item of serviceList; track item.id; let idx = $index) {
                <div class="info-item" id="{{item.id}}" [ngClass]="{'active' : item.active}">
                    <p class="item-number">{{idx + 1}}.</p>
                    <div class="item-content">
                        <h2>{{item.title}}</h2>
                        <p>{{item.description}}</p>
                    </div>
                </div>
                }
            </div>
        </div>

    </div>

    <div class="contact-cta">
        <p>Let's build something great together. Contact us today to learn more about how we can bring your vision to life.</p>
        <a class="button" href="/lets-chat">
            Let's Chat
        </a>
    </div>



</div>