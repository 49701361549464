import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceItemModel } from 'src/app/models/service-item.model';

@Component({
  selector: 'tsg-design',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './design.component.html',
  styleUrl: './design.component.scss'
})
export class DesignComponent implements OnInit, AfterViewInit {

  @HostListener("window:scroll", ['$event']) onWindowScroll() {
    this.trackStickyBar();
    this.trackServiceLocationOnPage();
  }
  @HostListener('window:resize', ['$event']) onResize() {
    this.trackStickyBar();
    this.trackServiceLocationOnPage();
  }

  serviceList: Array<ServiceItemModel> | undefined
  fixedList: boolean | undefined = false;
  absoluteList: boolean | undefined = false;
  pauseScrollTracking: boolean | undefined = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {};

  ngOnInit(): void {

  
    this.initServices();
    this.getServiceByRoute();
  }

  ngAfterViewInit(): void {
    // this.trackStickyBar();
  }

  initServices():void {
    this.serviceList = [ 
      { 
        active: true,
        id: 'discovery', 
        title: 'Discovery & Research',
        description: 'This is a deep dive into understanding your business, goals, target audience, and competitors. We conduct thorough research to inform our design decisions and ensure that the final product aligns with your brand and objectives.',
      },
      { 
        active: false,
        id: 'info-architecture', 
        title: 'Information Architecture & Site Mapping',
        description: 'Based on the material gathered in the Discovery & Research phase, we can develop your website\'s information architecture. This includes creating a site map that outlines the structure of the website, ensuring that all necessary content is organized and accessible.',
      },
      { 
        active: false,
        id: 'wireframes', 
        title: 'Wireframes',
        description: 'Utilizing the site map the Information Architecture & Site Mapping phase, we create a basic visual representations of the website\'s layout and structure. These wireframes serve as a blueprint for the site, focusing on the placement of elements, navigation, and user flow without the distraction of design elements.',
      },
      { 
        active: false,
        id: 'mockups', 
        title: 'Design Mockups',
        description: 'Upon wireframe approval, we begin the low-fideltiy design mockups. These are a more detailed visual representation of the website, incorporating your brand\'s colors, typography, and imagery. The end result includes several design options and iterations based on your feedback.',
      },
      { 
        active: false,
        id: 'hifi-comps', 
        title: 'High-Fidelity Comps',
        description: 'Once the design direction is established, we refine the selected mockups into high-fidelity comps. These are polished, detailed designs that closely resemble the final product. High-fidelity comps include all visual elements and are used as a reference for the development phase.',
      },
      { 
        active: false,
        id: 'prototypes', 
        title: 'Interactive Prototypes',
        description: 'To ensure the design works as intended, we may create interactive prototypes. These prototypes simulate user interactions, allowing you to experience the site\'s functionality before development begins. This step can be crucial for identifying any potential usability issues.',
      },
      { 
        active: false,
        id: 'review-approval', 
        title: 'Client Review & Approval',
        description: 'Throughout this entire process, we maintain open communication with you. We present each stage for your review and feedback, making necessary adjustments to ensure the design meets your expectations. Once the high-fidelity comps and prototypes are approved, we move on to the development phase.',
      },
      { 
        active: false,
        id: 'support', 
        title: 'Post-Launch Support',
        description: 'After your website goes live, we continue to offer support, including design updates, performance monitoring, and user experience enhancements. Our goal is to ensure your website remains effective and up-to-date.',
      },
    ]
  }

  // select service based on route
  getServiceByRoute(): void {
    var serviceURL = this.router.url.split('#');

    if (serviceURL.length > 1) {
      var serviceQuery = serviceURL[1];
      let item = this.serviceList?.find(s => s.id == serviceQuery);
      if (item) {
        this.selectServiceItem(item);
      }
    }
  }
  // select service
  selectServiceItem(item: ServiceItemModel) {
    this.pauseScrollTracking = true;
    this.serviceList?.forEach(service => {
      service.active = false;
    });
    item.active = true;

    setTimeout(() => {
      this.pauseScrollTracking = false;
    }, 1000);
    

    if (item.id && item.title) {
      var pageURL = `/services/design#`;
      window.history.pushState("", item.title, pageURL + item.id);
      
      var element = document.getElementById(item.id);
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

  }

  // sticky bar functionality
  trackStickyBar() {
    var stickyBar = document.getElementById('sticky-bar');
    var stickyList = document.getElementById('sticky-list');

    var barHeight = stickyBar?.offsetHeight;
    var listHeight = stickyList?.offsetHeight;

    var distanceToTop = stickyBar?.getBoundingClientRect().top;
    this.fixedList = distanceToTop && distanceToTop <= 50 ? true : false;

    if (barHeight && listHeight && distanceToTop) {
      var adjustedBarHeight = barHeight - listHeight;

      this.absoluteList = distanceToTop <= -adjustedBarHeight + 50 ? true : false;
    }
  }

  trackServiceLocationOnPage() {
    // pauseScrollTracking will pause this function while clicking around the sticky nav
    if (!this.pauseScrollTracking) {
      this.serviceList?.forEach((service, index, array) => {
        if (service.id) {
          var s = document.getElementById(service.id);
          var serviceDistanceToTop = s?.getBoundingClientRect().top;
          var serviceHeight = s?.offsetHeight;
          // Account for padding-bottom changing on smaller devices
          var buffer = window.innerWidth >= 520 ? 150 : 100;

          if (serviceDistanceToTop && serviceHeight) {
            // Only affect last service item
            if (index === array.length - 1) {
              service.active = (serviceDistanceToTop <= buffer) ? true : false;
            } 
            // Only affect first service item
            else if (index > 0) {
              service.active = (serviceDistanceToTop <= buffer && serviceDistanceToTop >= -serviceHeight + buffer) ? true : false;
            } 
            // Everything in-between
            else {
              service.active = (serviceDistanceToTop >= -serviceHeight + buffer) ? true : false;
            }
          }
        }
      });
    }

  }
}
