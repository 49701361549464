<footer>
    <div class="footer-container">
        <div class="back-to-top" (click)="scrollToTop()">
            <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.15886C4.41438 8.15886 8.02587 4.53761 8.02587 0H12.0259V0.0379376C12.0333 4.569 15.6419 8.18281 20.0517 8.18281V12.1828C16.9591 12.1828 14.15 11.0096 12.0259 9.08403L12.0259 26.0067H8.02587L8.02587 9.06007C5.90178 10.9857 3.09268 12.1589 0 12.1589V8.15886Z" />
            </svg>
            <p>Back to Top</p>
        </div>

        <div class="footer-links">
            <a href="/about-us">About</a>

            <div class="dropdown">
                <p>Services</p>
                <div class="dropdown-menu">
                    <a href="/services/development">Web Development</a>
                    <a href="services/design">Web Design</a>
                    <a href="/services/strategy">Strategy & SEO</a>
                </div>
            </div>
            
            <!-- <a href="/">Portfolio</a> -->
            <a href="/lets-chat">Contact Us</a>
        </div>

        <div class="local-data">
            <p>Located in Lancaster, PA</p>
            <p>Local time: {{currentTimeEST}}</p>
            @if(currentShortForecast) {
                <p>Forecast: {{currentShortForecast}}</p>
            }
            @if(currentTemp) {
                <p>Temp: {{currentTemp}}</p>
            }
            <!-- <p>It is {{currentTimeEST}} in Lancaster, PA. The local forecast is "{{this.currentShortForecast}}" with a current temperature of {{this.currentTemp}}</p> -->
        </div>


        <a class="logo" href="/">
            <div class="monogram-container">
                <div class="ball bounce-on-load"></div>
                <div class="stick"></div>
            </div>
            <div class="letter-container">
                <svg width="513" height="108" viewBox="0 0 513 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M424.092 93.1121C425.214 95.1494 426.452 97.0719 427.804 98.8796C424.322 101.089 420.467 102.868 416.18 104.274C410.598 106.082 405.016 107 399.463 107C391.493 107 384.328 105.278 378.027 101.806C371.697 98.3344 366.691 93.5999 363.065 87.6315C359.411 81.6632 357.57 74.8914 357.57 67.3162C357.57 61.6921 358.663 56.4698 360.821 51.6492C363.008 46.8286 366 42.6106 369.885 39.0239C373.74 35.4371 378.286 32.6251 383.494 30.6452C388.702 28.6653 394.313 27.661 400.355 27.661C406.397 27.661 411.634 28.4645 416.669 30.1C420.899 31.4773 424.783 33.3711 428.322 35.8388C425.473 39.5117 423.2 43.6149 421.474 48.0625C420.467 50.6736 419.69 53.3709 419.115 56.1829L418.741 56.8429C416.324 54.576 413.705 52.9118 410.914 51.8501C408.095 50.8171 405.16 50.3006 402.11 50.3006C398.254 50.3006 394.974 50.9606 392.298 52.3092C389.594 53.6291 387.493 55.5516 386.055 58.0767C384.587 60.6018 383.868 63.7007 383.868 67.3162C383.868 72.6533 385.479 76.8426 388.759 79.8555C392.011 82.8683 396.47 84.3604 402.167 84.3604C405.591 84.3604 408.814 83.7292 411.921 82.4379C414.396 81.4049 416.726 79.9702 418.942 78.1625C419.92 83.5857 421.618 88.6071 424.092 93.1121Z" fill="white"/>
                    <path d="M67.6277 32.8547C61.9595 29.3827 55.4281 27.661 48.0335 27.661C42.6243 27.661 37.6754 28.7227 33.2156 30.8174C31.144 31.793 29.2162 33.0268 27.3747 34.4902V0H0.961426V104.819H23.6631V96.6702C24.4112 97.5884 25.2168 98.4492 26.1375 99.2813C28.8709 101.72 32.1222 103.614 35.949 104.963C39.7758 106.311 43.9766 107 48.5514 107C53.9032 107 58.8521 106.082 63.3694 104.245C67.8867 102.409 71.8285 99.7404 75.1949 96.2684C78.5613 92.7965 81.1796 88.6071 83.0499 83.7005C84.9201 78.7938 85.8408 73.3419 85.8408 67.3162C85.8408 59.7983 84.2296 53.0265 81.007 47.0295C77.7557 41.0324 73.3247 36.2979 67.6277 32.826V32.8547ZM57.8162 76.4122C56.4064 78.9659 54.4498 80.9171 51.9754 82.2944C49.5009 83.6718 46.71 84.3604 43.6601 84.3604C40.6102 84.3604 37.848 83.6718 35.3735 82.2944C32.9279 80.9171 30.9713 78.9372 29.5327 76.4122C28.0941 73.8584 27.3747 70.8455 27.3747 67.3449C27.3747 63.8442 28.0653 60.8313 29.4751 58.2776C30.885 55.7238 32.8128 53.7726 35.2872 52.3666C37.7329 50.9893 40.5239 50.3006 43.6313 50.3006C46.7388 50.3006 49.5297 50.9893 52.0042 52.3666C54.4498 53.7439 56.3776 55.7238 57.7875 58.2776C59.1973 60.8313 59.8879 63.8442 59.8879 67.3449C59.8879 70.8455 59.1973 73.8584 57.7875 76.4122H57.8162Z" fill="white"/>
                    <path d="M154.723 33.0842C148.364 29.4688 141.056 27.661 132.798 27.661C126.612 27.661 120.944 28.7514 115.765 30.9035C110.585 33.0555 106.126 35.9823 102.385 39.6838C98.6448 43.3854 95.7387 47.6034 93.6959 52.3379C91.653 57.1011 90.6172 62.0939 90.6172 67.3449C90.6172 74.5184 92.4011 81.118 95.9977 87.1437C99.5943 93.1695 104.543 97.9901 110.873 101.606C117.232 105.221 124.54 107.029 132.827 107.029C139.042 107.029 144.739 105.938 149.86 103.786C154.982 101.634 159.441 98.7074 163.239 95.0059C167.009 91.3331 169.915 87.0864 171.958 82.2945C174 77.5026 175.036 72.5385 175.036 67.3449C175.036 60.1714 173.252 53.5717 169.656 47.546C166.088 41.5203 161.11 36.7283 154.78 33.0842H154.723ZM146.897 76.4122C145.458 78.9659 143.53 80.9171 141.056 82.2945C138.61 83.6718 135.848 84.3604 132.769 84.3604C129.69 84.3604 126.928 83.6718 124.454 82.2945C121.979 80.9171 120.023 78.9372 118.613 76.4122C117.203 73.8584 116.484 70.8455 116.484 67.3449C116.484 63.8442 117.203 60.8313 118.613 58.2776C120.023 55.7238 121.951 53.7726 124.396 52.3666C126.842 50.9893 129.633 50.3006 132.769 50.3006C135.905 50.3006 138.668 50.9893 141.113 52.3666C143.559 53.7439 145.515 55.7238 146.925 58.2776C148.335 60.8313 149.026 63.8442 149.026 67.3449C149.026 70.8455 148.306 73.8584 146.868 76.4122H146.897Z" fill="white"/>
                    <path d="M231.344 70.5299C231.344 72.9976 230.855 75.1783 229.905 77.0721C228.956 78.9659 227.575 80.4293 225.82 81.491C224.036 82.524 221.935 83.0405 219.49 83.0405C215.692 83.0405 212.843 81.9788 210.944 79.8267C209.045 77.7034 208.096 74.7192 208.096 70.9603V29.8418H181.51V74.6905C181.51 81.2328 182.603 86.9429 184.761 91.7921C186.919 96.6414 190.257 100.4 194.774 103.04C199.263 105.68 204.988 107 211.894 107C218.022 107 222.885 105.967 226.453 103.93C230.021 101.892 232.927 99.6256 235.171 97.1579V104.791H257.872V29.8418H231.315V70.5299H231.344Z" fill="white"/>
                    <path d="M335.933 31.8217C331.358 29.067 325.488 27.661 318.353 27.661C314.181 27.661 310.239 28.4358 306.527 29.9566C302.844 31.4773 299.651 33.9163 296.975 37.2735V29.8418H270.417V104.819H296.975V65.5085C296.975 62.7825 297.493 60.3722 298.528 58.2776C299.564 56.1542 301.089 54.5186 303.046 53.3709C305.031 52.2231 307.362 51.6205 310.095 51.6205C314.382 51.6205 317.576 52.797 319.705 55.1786C321.806 57.5602 322.87 60.8887 322.87 65.1354V104.819H349.456V56.7855C349.456 51.7927 348.363 47.0582 346.118 42.6393C343.903 38.1917 340.508 34.605 335.933 31.8217Z" fill="white"/>
                    <path d="M486.414 0V34.318C484.601 32.912 482.731 31.7069 480.717 30.7887C476.228 28.694 471.308 27.661 465.899 27.661C460.49 27.661 455.282 28.6653 450.678 30.6739C446.075 32.6825 442.075 35.4945 438.709 39.0813C435.342 42.668 432.695 46.8573 430.854 51.6779C429.012 56.4698 428.063 61.6921 428.063 67.3162C428.063 75.3792 429.674 82.3805 432.926 88.3202C436.148 94.2599 440.608 98.8509 446.247 102.122C451.887 105.364 458.274 107 465.381 107C472.488 107 478.329 105.508 483.364 102.552C486.155 100.917 488.428 98.9657 490.27 96.7562V104.819H512.971V0H486.385H486.414ZM484.4 76.4122C482.961 78.9659 481.033 80.9171 478.559 82.2944C476.113 83.6718 473.351 84.3604 470.273 84.3604C467.194 84.3604 464.432 83.6718 461.957 82.2944C459.483 80.9171 457.526 78.9372 456.116 76.4122C454.706 73.8584 453.987 70.8455 453.987 67.3449C453.987 63.8442 454.706 60.8313 456.116 58.2776C457.526 55.7238 459.454 53.7726 461.9 52.3666C464.345 50.9893 467.136 50.3006 470.273 50.3006C473.409 50.3006 476.171 50.9893 478.617 52.3666C481.062 53.7439 483.019 55.7238 484.429 58.2776C485.839 60.8313 486.529 63.8442 486.529 67.3449C486.529 70.8455 485.81 73.8584 484.371 76.4122H484.4Z" fill="white"/>
                </svg>
            </div>
        </a>

        <div class="legal">
            <a href="/privacy-policy">Privacy Policy</a>
        </div>
    </div>
</footer>
