import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceItemModel } from 'src/app/models/service-item.model';

@Component({
  selector: 'tsg-development',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './development.component.html',
  styleUrl: './development.component.scss'
})
export class DevelopmentComponent implements OnInit, AfterViewInit {

  @HostListener("window:scroll", ['$event']) onWindowScroll() {
    this.trackStickyBar();
    this.trackServiceLocationOnPage();
  }
  @HostListener('window:resize', ['$event']) onResize() {
    this.trackStickyBar();
    this.trackServiceLocationOnPage();
  }

  serviceList: Array<ServiceItemModel> | undefined
  fixedList: boolean | undefined = false;
  absoluteList: boolean | undefined = false;
  pauseScrollTracking: boolean | undefined = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {};

  ngOnInit(): void {

  
    this.initServices();
    this.getServiceByRoute();
  }

  ngAfterViewInit(): void {
    // this.trackStickyBar();
  }

  initServices():void {
    this.serviceList = [ 
      { 
        active: true,
        id: 'front-end', 
        title: 'Front-End Development',
        description: 'Our front-end development team transforms design mockups into interactive and responsive web pages. Using the latest technologies such as HTML5, CSS3, and JavaScript frameworks like React, Angular, or Vue.js, we ensure your website is visually appealing, user-friendly, and performs seamlessly across all devices and browsers.',
      },
      { 
        active: false,
        id: 'back-end', 
        title: 'Back-End Development',
        description: 'Behind every great website is a powerful back-end. Our back-end development team builds the infrastructure that powers your site, ensuring it\'s secure, scalable, and efficient. We work with a variety of programming languages and frameworks, including Node.js, Python, Ruby on Rails, and PHP, to create custom solutions that meet your specific business needs.',
      },
      { 
        active: false,
        id: 'db-management', 
        title: 'Database Management',
        description: 'Data is at the heart of many modern websites and applications. We design and implement robust database solutions that store, manage, and retrieve your data efficiently. Whether using SQL databases like MySQL and PostgreSQL, or NoSQL options like MongoDB, we ensure your data is secure and accessible.',
      },
      { 
        active: false,
        id: 'api-development', 
        title: 'API Development & Integration',
        description: 'To extend your website\'s functionality, we develop and integrate APIs (Application Programming Interfaces). Whether you need to connect third-party services, enable mobile apps to interact with your website, or create custom APIs for unique requirements, our team ensures seamless communication between your website and other platforms.',
      },
      { 
        active: false,
        id: 'cms', 
        title: 'Content Management System (CMS) Integration',
        description: 'We offer CMS integration to give you control over your website\'s content without needing technical expertise. We work with popular CMS platforms like WordPress, Drupal, and Joomla, or create custom CMS solutions tailored to your needs, allowing you to easily update content, manage media, and more.',
      },
      { 
        active: false,
        id: 'e-comm', 
        title: 'E-Commerce Development',
        description: 'For businesses looking to sell online, we provide comprehensive e-commerce development services. From setting up product catalogs and shopping carts to payment gateway integration and secure checkout processes, we build e-commerce solutions that drive sales and enhance the customer experience.',
      },
      { 
        active: false,
        id: 'qa-testing', 
        title: 'Quality Assurance & Testing',
        description: 'Before your website goes live, it undergoes rigorous quality assurance testing. Our QA team conducts extensive testing across different devices, browsers, and scenarios to ensure everything works flawlessly. This includes functional testing, performance testing, security testing, and usability testing.',
      },
      { 
        active: false,
        id: 'hosting', 
        title: 'Hosting & Deployment',
        description: 'Once development and testing are complete, we assist with hosting and deployment. We recommend reliable hosting solutions tailored to your website\'s needs, whether shared hosting, VPS, or dedicated servers. We manage the deployment process to ensure a smooth and successful launch.',
      },
      { 
        active: false,
        id: 'support', 
        title: 'Maintenance & Support',
        description: 'Our commitment to your website doesn\'t end at launch. We offer ongoing maintenance and support services to keep your website up-to-date, secure, and running smoothly. Whether you need regular updates, performance monitoring, or emergency support, our team is here to help.',
      },
      { 
        active: false,
        id: 'post-paunch', 
        title: 'Post-Launch Optimization',
        description: 'After launch, we analyze your website\'s performance and user behavior to identify opportunities for improvement. We provide ongoing optimization services, including speed enhancements, SEO improvements, and feature upgrades, to ensure your website continues to meet your business goals.',
      },
    ]
  }

  // select service based on route
  getServiceByRoute(): void {
    var serviceURL = this.router.url.split('#');

    if (serviceURL.length > 1) {
      var serviceQuery = serviceURL[1];
      let item = this.serviceList?.find(s => s.id == serviceQuery);
      if (item) {
        this.selectServiceItem(item);
      }
    }
  }
  // select service
  selectServiceItem(item: ServiceItemModel) {
    this.pauseScrollTracking = true;
    this.serviceList?.forEach(service => {
      service.active = false;
    });
    item.active = true;

    setTimeout(() => {
      this.pauseScrollTracking = false;
    }, 1000);
    

    if (item.id && item.title) {
      var pageURL = `/services/development#`;
      window.history.pushState("", item.title, pageURL + item.id);
      
      var element = document.getElementById(item.id);
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

  }

  // sticky bar functionality
  trackStickyBar() {
    var stickyBar = document.getElementById('sticky-bar');
    var stickyList = document.getElementById('sticky-list');

    var barHeight = stickyBar?.offsetHeight;
    var listHeight = stickyList?.offsetHeight;

    var distanceToTop = stickyBar?.getBoundingClientRect().top;
    this.fixedList = distanceToTop && distanceToTop <= 50 ? true : false;

    if (barHeight && listHeight && distanceToTop) {
      var adjustedBarHeight = barHeight - listHeight;

      this.absoluteList = distanceToTop <= -adjustedBarHeight + 50 ? true : false;
    }
  }

  trackServiceLocationOnPage() {
    // pauseScrollTracking will pause this function while clicking around the sticky nav
    if (!this.pauseScrollTracking) {
      this.serviceList?.forEach((service, index, array) => {
        if (service.id) {
          var s = document.getElementById(service.id);
          var serviceDistanceToTop = s?.getBoundingClientRect().top;
          var serviceHeight = s?.offsetHeight;
          // Account for padding-bottom changing on smaller devices
          var buffer = window.innerWidth >= 520 ? 150 : 100;

          if (serviceDistanceToTop && serviceHeight) {
            // Only affect last service item
            if (index === array.length - 1) {
              service.active = (serviceDistanceToTop <= buffer) ? true : false;
            } 
            // Only affect first service item
            else if (index > 0) {
              service.active = (serviceDistanceToTop <= buffer && serviceDistanceToTop >= -serviceHeight + buffer) ? true : false;
            } 
            // Everything in-between
            else {
              service.active = (serviceDistanceToTop >= -serviceHeight + buffer) ? true : false;
            }
          }
        }
      });
    }

  }
}
