import { NgClass, CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, HostListener, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'tsg-navigation',
  standalone: true,
  imports: [
    NgClass,
    CommonModule
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit, AfterViewInit {

  openDevelopersDropdown: boolean | undefined;
  openProductDropdown: boolean | undefined;
  openSolutionsDropdown: boolean | undefined;

  openDrawer: boolean | undefined;
  openDrawerDropdown: boolean | undefined;

  loaded: boolean | undefined = false;

  @HostListener('window:resize', []) onWindowResize() {
    this.killMenus();
  }

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    
    // remove animation class after load +1s (to let animation finish)
    setTimeout(() => {
      this.loaded = true;
    }, 1000)
  }


  toggleDevelopersDropdown() {
    this.openDevelopersDropdown = !this.openDevelopersDropdown;
    this.openProductDropdown = false;
    this.openSolutionsDropdown = false;
  }
  toggleProductDropdown() {
    this.openDevelopersDropdown = false;
    this.openProductDropdown = !this.openProductDropdown;
    this.openSolutionsDropdown = false;

  }
  toggleSolutionsDropdown() {
    this.openDevelopersDropdown = false;
    this.openProductDropdown = false;
    this.openSolutionsDropdown = !this.openSolutionsDropdown;
  }
  toggleDrawer() {
    this.openDrawer = !this.openDrawer;
  }
  toggleDrawerDropdown() {
    this.openDrawerDropdown = !this.openDrawerDropdown;
  }
  killMenus() {
    this.openDevelopersDropdown = false;
    this.openProductDropdown = false;
    this.openSolutionsDropdown = false;
    this.openDrawer = false;
  }
  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }
  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
  
}
