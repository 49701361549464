<div class="page-banner">

    <div class="banner-container">
        <h1>Cohesive Web Strategy & SEO Services</h1>
        <p>We combine customized research, planning, and tailored SEO practices to elevate your online presence and drive measurable results to your website.</p>
    </div>
</div>

<div class="page-content">

    <div class="page-wrapper service-wrapper">

        <div class="sticky-bar" id="sticky-bar">
            <div class="sticky-list" id="sticky-list" [ngClass]="{'fixed' : fixedList, 'absolute' : absoluteList}">
                @for (item of serviceList; track item.id) {
                <p [ngClass]="{'active' : item.active}" (click)="selectServiceItem(item)">{{item.title}}</p>
                }
            </div>
        </div>

        <div class="info-column">
            <div class="info-list">
                @for (item of serviceList; track item.id; let idx = $index) {
                <div class="info-item" id="{{item.id}}" [ngClass]="{'active' : item.active}">
                    <p class="item-number">{{idx + 1}}.</p>
                    <div class="item-content">
                        <h2>{{item.title}}</h2>
                        <p>{{item.description}}</p>
                    </div>
                </div>
                }
            </div>
        </div>

    </div>

    <div class="contact-cta">
        <p>Ready to optimize your website traffic? Contact us today to learn more about how we can bring your vision to life.</p>
        <a class="button" href="/lets-chat">
            Let's Chat
        </a>
    </div>



</div>