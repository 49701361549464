import { Component, OnInit } from '@angular/core';
import { Location, NgClass } from '@angular/common';

@Component({
  selector: 'tsg-footer',
  standalone: true,
  imports: [NgClass],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {

  currentUrl: string | undefined;
  currentTimeEST: string | undefined;
  currentShortForecast: string | undefined;
  currentTemp: string | undefined;
  // isHomePage: boolean | undefined;

  constructor(
    private location: Location,
  ) {
    this.currentUrl = this.location.path();
  }

  ngOnInit(): void {
    // this.isHomePage = false;
    // console.log(this.currentUrl)
    // if (this.currentUrl == '') {
    //   this.isHomePage = true;
    // }

    // setInterval(() => {
    //   this.getTime();
    // }, 1000);

    this.getWeather();
    this.getTime();
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      // top: 160,
      behavior: "smooth",
    });
  }

  getTime() {
    const easternTime = new Date().toLocaleTimeString("en-US", {timeZone: 'America/New_York'});

    this.currentTimeEST = easternTime
    // console.log(easternTime)/

    setTimeout(() => {
      this.getTime();
    }, 1000);
  }


  // Get Weather
  async getWeather() {
    const url = "https://api.weather.gov/gridpoints/CTP/120,27/forecast";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const json = await response.json();
      // Forecast
      this.currentShortForecast = json.properties.periods[0].shortForecast;
      this.currentTemp = json.properties.periods[0].temperature + '° ' + json.properties.periods[0].temperatureUnit;
      console.log(json);
    } catch (error) {
      console.log('error');
    }
  }
  

}
