import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ContactForm } from 'models/ContactForm';
import { ApiService } from 'src/app/api.service'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'tsg-contact-us',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit {

  constructor(
    private apiService: ApiService
    ) {}

  interestedInItems: Array<string> | undefined;

  // Dealer Zip Lookup
  contactUsForm = new FormGroup({
    fullName: new FormControl('', [
      Validators.required,
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    phone: new FormControl('', []),
    interestedInWebDesign: new FormControl('', []),
    interestedInWebDevelopment: new FormControl('', []),
    interestedInStrategy: new FormControl('', []),
    interestedInBranding: new FormControl('', []),
    interestedInOther: new FormControl('', []),
    otherInterest: new FormControl('', []),
    websiteURL: new FormControl('', []),
    message: new FormControl('', [
      Validators.required,
    ]),
  });
  
  launch: boolean | undefined;
  sending: boolean | undefined;
  showFeedback: boolean | undefined;
  feedbackMessage: string | undefined;
  
  ngOnInit(): void {
    this.launch = false;
    this.sending = false;
    this.showFeedback = false;
    this.feedbackMessage = "Got it!";

    this.interestedInItems = [];
  }

  updatePhoneDisplayInForm(e?: KeyboardEvent): boolean {
    var value = this.contactUsForm.value.phone;
    
    if (value) {
      var result = this.formatPhone(value, e);

      if (result) {
        window.setTimeout(() => {
          this.contactUsForm.controls['phone'].setValue(result.display, { emitEvent: false });
        }, 0);
      }
    }
    return true;
  }

  formatPhone(value: string, e?: KeyboardEvent): { display: string, value: string } {
    var output = '';
    var input = value.replace(/[^0-9]/g, '');

    // do not allow non-numeric or empty values
    if (e !== undefined  && ((e.shiftKey === true && e.keyCode > 47) || (e.keyCode > 57 && e.keyCode < 96) || e.keyCode > 105 || !value)) {
      e.preventDefault();
    } else {
      if (input.length > 6) {
        output = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6);
      } else if (input.length > 3) {
        output = '(' + input.substring(0, 3) + ') ' + input.substring(3);
      } else {
        output = input;
      }
    }

    const retVal = {
      display: output,
      value: output.replace(/[^0-9]/g, '')
    }

    return retVal;
  }

  checkForInterestSelection() {
    let validInterest = 
    this.contactUsForm.value.interestedInWebDesign ||
    this.contactUsForm.value.interestedInWebDevelopment ||
    this.contactUsForm.value.interestedInStrategy ||
    this.contactUsForm.value.interestedInBranding ||
    (this.contactUsForm.value.interestedInOther && this.contactUsForm.value.otherInterest) ? true : false;

    return validInterest
  }

  initSubmit() {
    this.launch = true;

    // Add a small timeout for transition / animation effect of the arrow
    setTimeout(() => {
      this.submitLoginForm();
    }, 200)
    // console.log(this.contactUsForm.value);
  }

  submitLoginForm() {
    this.sending = true;
    this.launch = false;

    // arbitrary timeout to allow messages to show
    // codes too good and too fast to read feedback messages lol
    setTimeout(() => {
      const formData = this.contactUsForm.value as ContactForm;
      this.apiService.mail(formData);

      if (this.apiService.success) {
        this.showFormFeedback(true);
      } else {
        this.showFormFeedback(false);
      }
    }, 1000)
  }

  showFormFeedback(isSuccess: boolean) {
    this.showFeedback = true;
    this.sending = false;

    if (isSuccess) {
      this.feedbackMessage = 'Got It!'
      this.contactUsForm.reset();
    } else {
      // Need to pop message elsewhere
      this.feedbackMessage = 'Whoops?'
    }

    setTimeout(() => {
      this.showFeedback = false;
    }, 2000)
    
  }



}
