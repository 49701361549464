import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceItemModel } from 'src/app/models/service-item.model';

@Component({
  selector: 'tsg-strategy',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './strategy.component.html',
  styleUrl: './strategy.component.scss'
})
export class StrategyComponent implements OnInit, AfterViewInit {

  @HostListener("window:scroll", ['$event']) onWindowScroll() {
    this.trackStickyBar();
    this.trackServiceLocationOnPage();
  }
  @HostListener('window:resize', ['$event']) onResize() {
    this.trackStickyBar();
    this.trackServiceLocationOnPage();
  }

  serviceList: Array<ServiceItemModel> | undefined
  fixedList: boolean | undefined = false;
  absoluteList: boolean | undefined = false;
  pauseScrollTracking: boolean | undefined = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {};

  ngOnInit(): void {

  
    this.initServices();
    this.getServiceByRoute();
  }

  ngAfterViewInit(): void {
    // this.trackStickyBar();
  }

  initServices():void {
    this.serviceList = [ 
      { 
        active: true,
        id: 'personas', 
        title: 'Audience Research & Persona Development',
        description: 'Understanding your audience is the foundation of any successful web strategy. We begin by conducting in-depth research to identify your target market’s demographics, behaviors, and pain points. From this research, we create detailed buyer personas that represent your ideal customers, ensuring that your website and content are tailored to their needs and preferences.',
      },
      { 
        active: false,
        id: 'market-research', 
        title: 'Competitive Analysis & Market Research',
        description: 'To give your business a competitive edge, we perform a thorough analysis of your industry and competitors. We identify the strengths and weaknesses of your competitors’ online presence, uncovering opportunities for your website to stand out. This research informs our strategy and helps us position your brand effectively in the market.',
      },
      { 
        active: false,
        id: 'audit', 
        title: 'SEO Audit & Strategy Development',
        description: 'A successful web strategy starts with a solid foundation. We conduct a comprehensive SEO audit of your existing website to identify areas for improvement. This includes analyzing your site\'s structure, content, keyword usage, backlinks, and technical SEO factors. Based on our findings, we develop a tailored SEO strategy that enhances your website’s visibility and ranking on search engines.',
      },
      { 
        active: false,
        id: 'keyword-optimization', 
        title: 'Keyword Research & Optimization',
        description: 'Effective SEO begins with the right keywords. We perform extensive keyword research to identify the terms and phrases your target audience is searching for. We then optimize your website\'s content, meta tags, headers, and URLs to ensure that these keywords are strategically incorporated, improving your site\'s relevance and search engine ranking.',
      },
      { 
        active: false,
        id: 'content-strategy', 
        title: 'Content Strategy & Creation',
        description: 'Content is key to engaging your audience and improving SEO. We develop a content strategy that aligns with your business goals and resonates with your target audience. Our team creates high-quality, SEO-optimized content that not only drives traffic but also establishes your brand as an authority in your industry. This includes website copy, blog posts, case studies, and more.',
      },
      { 
        active: false,
        id: 'seo-optimization', 
        title: 'On-Page SEO & Technical Optimization',
        description: 'To ensure your website performs at its best, we focus on both on-page and technical SEO. This includes optimizing your site\'s load speed, mobile responsiveness, and user experience (UX). We also ensure proper indexing, improve site navigation, and implement schema markup to enhance your site\'s search engine performance.',
      },
      { 
        active: false,
        id: 'link-building', 
        title: 'Link Building & Off-Page SEO',
        description: 'Building authority and trust is crucial for SEO success. We implement a strategic link-building campaign to acquire high-quality backlinks from reputable websites. Off-page SEO efforts like guest blogging, influencer outreach, and social media engagement help boost your site\'s credibility and search engine ranking.',
      },
      { 
        active: false,
        id: 'google-business', 
        title: 'Local SEO & Google My Business Optimization',
        description: 'For businesses targeting a local audience, we implement a local SEO strategy that ensures your website ranks well in local search results. We optimize your Google My Business profile, create location-based content, and manage online reviews to enhance your visibility in local searches and attract more customers from your area.',
      },
      { 
        active: false,
        id: 'analytics', 
        title: 'Analytics & Performance Tracking',
        description: 'Data is at the heart of our web strategy and SEO approach. We set up analytics tools to monitor your website\'s performance, tracking key metrics like traffic, bounce rate, conversion rates, and keyword rankings. Regular reporting and analysis allow us to measure the effectiveness of our strategy and make data-driven adjustments as needed.',
      },
      { 
        active: false,
        id: 'strategy-refinement', 
        title: 'Continuous Optimization & Strategy Refinement',
        description: 'SEO is not a one-time effort; it requires ongoing optimization. We continuously refine your web strategy and SEO efforts to adapt to changes in search engine algorithms, market trends, and your business needs. Our proactive approach ensures that your website remains competitive and continues to deliver results over time.',
      },
    ]
  }

  // select service based on route
  getServiceByRoute(): void {
    var serviceURL = this.router.url.split('#');

    if (serviceURL.length > 1) {
      var serviceQuery = serviceURL[1];
      let item = this.serviceList?.find(s => s.id == serviceQuery);
      if (item) {
        this.selectServiceItem(item);
      }
    }
  }
  // select service
  selectServiceItem(item: ServiceItemModel) {
    this.pauseScrollTracking = true;
    this.serviceList?.forEach(service => {
      service.active = false;
    });
    item.active = true;

    setTimeout(() => {
      this.pauseScrollTracking = false;
    }, 1000);
    

    if (item.id && item.title) {
      var pageURL = `/services/strategy#`;
      window.history.pushState("", item.title, pageURL + item.id);
      
      var element = document.getElementById(item.id);
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

  }

  // sticky bar functionality
  trackStickyBar() {
    var stickyBar = document.getElementById('sticky-bar');
    var stickyList = document.getElementById('sticky-list');

    var barHeight = stickyBar?.offsetHeight;
    var listHeight = stickyList?.offsetHeight;

    var distanceToTop = stickyBar?.getBoundingClientRect().top;
    this.fixedList = distanceToTop && distanceToTop <= 50 ? true : false;

    if (barHeight && listHeight && distanceToTop) {
      var adjustedBarHeight = barHeight - listHeight;

      this.absoluteList = distanceToTop <= -adjustedBarHeight + 50 ? true : false;
    }
  }

  trackServiceLocationOnPage() {
    // pauseScrollTracking will pause this function while clicking around the sticky nav
    if (!this.pauseScrollTracking) {
      this.serviceList?.forEach((service, index, array) => {
        if (service.id) {
          var s = document.getElementById(service.id);
          var serviceDistanceToTop = s?.getBoundingClientRect().top;
          var serviceHeight = s?.offsetHeight;
          // Account for padding-bottom changing on smaller devices
          var buffer = window.innerWidth >= 520 ? 150 : 100;

          if (serviceDistanceToTop && serviceHeight) {
            // Only affect last service item
            if (index === array.length - 1) {
              service.active = (serviceDistanceToTop <= buffer) ? true : false;
            } 
            // Only affect first service item
            else if (index > 0) {
              service.active = (serviceDistanceToTop <= buffer && serviceDistanceToTop >= -serviceHeight + buffer) ? true : false;
            } 
            // Everything in-between
            else {
              service.active = (serviceDistanceToTop >= -serviceHeight + buffer) ? true : false;
            }
          }
        }
      });
    }

  }
}
