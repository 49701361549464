<!-- <div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  ...Loading
</div>

<ng-template #loaded>
 ... Loaded content
</ng-template> -->


<div class="welcome-banner">

  <div class="banner-container">
      <h1>A digital partner to grow your online business, brand & strategy</h1>

      <svg class="desktop" width="867" height="326" viewBox="0 0 867 326" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.52531 60.7149L23.1885 1.27374H43.5775L65.1557 60.7149H45.7863L42.4731 50.1004H23.6983L20.3851 60.7149H1.52531ZM27.3513 35.9194H38.7351L33.1281 18.9363L27.3513 35.9194Z" fill="white"/>
          <path d="M90.2018 60.7149V1.27374H111.78C118.123 1.27374 123.674 2.53332 128.431 5.0525C133.188 7.54337 136.884 11.0249 139.518 15.4972C142.151 19.9411 143.468 25.1068 143.468 30.9943C143.468 35.4382 142.717 39.4859 141.217 43.1373C139.716 46.7887 137.564 49.9306 134.76 52.563C131.985 55.1671 128.658 57.1767 124.778 58.592C120.898 60.0073 116.566 60.7149 111.78 60.7149H90.2018ZM107.363 44.7507H111.355C113.451 44.7507 115.348 44.4818 117.047 43.944C118.775 43.3779 120.261 42.5287 121.507 41.3965C122.782 40.236 123.759 38.7924 124.438 37.0658C125.118 35.3392 125.458 33.3154 125.458 30.9943C125.458 27.8807 124.863 25.3049 123.674 23.267C122.484 21.229 120.828 19.7146 118.704 18.724C116.58 17.7333 114.131 17.2379 111.355 17.2379H107.363V44.7507Z" fill="white"/>
          <path d="M152.839 60.7149V1.27374H169.999V60.7149H152.839Z" fill="white"/>
          <path d="M213.111 61.9886C208.58 61.9886 204.29 61.2952 200.24 59.9082C196.191 58.4929 192.594 56.4549 189.451 53.7942C186.336 51.1335 183.873 47.8926 182.06 44.0714C180.276 40.2218 179.384 35.8628 179.384 30.9943C179.384 26.1824 180.248 21.8659 181.975 18.0446C183.703 14.2234 186.095 10.9683 189.154 8.27931C192.212 5.5903 195.738 3.53816 199.731 2.1229C203.723 0.707632 207.985 0 212.516 0C214.583 0 216.792 0.15568 219.142 0.467039C221.493 0.778395 223.928 1.35865 226.449 2.20782C228.997 3.05697 231.546 4.28826 234.094 5.90166L227.043 19.1061C225.089 17.8323 223.036 16.8983 220.884 16.3039C218.76 15.6811 216.424 15.3698 213.875 15.3698C211.213 15.3698 208.835 15.7378 206.739 16.4737C204.644 17.1813 202.874 18.2145 201.43 19.5731C200.014 20.9318 198.923 22.5876 198.159 24.5407C197.423 26.4938 197.055 28.7016 197.055 31.1641C197.055 34.7872 197.833 37.7876 199.391 40.1652C200.977 42.5429 203.072 44.312 205.677 45.4725C208.283 46.633 211.129 47.2133 214.215 47.2133C215.121 47.2133 216.07 47.1566 217.061 47.0434C218.052 46.9019 219.029 46.7321 219.992 46.5339V38.5518H208.693V25.1351H234.859V57.1484C232.933 58.1391 230.71 59.0024 228.19 59.7384C225.67 60.4743 223.093 61.0263 220.459 61.3942C217.826 61.7905 215.376 61.9886 213.111 61.9886Z" fill="white"/>
          <path d="M247.001 60.7149V1.27374H264.162V60.7149H247.001Z" fill="white"/>
          <path d="M287.139 60.7149V16.8983H271.848V1.27374H319.677V16.8983H304.385V60.7149H287.139Z" fill="white"/>
          <path d="M316.867 60.7149L338.53 1.27374H358.919L380.497 60.7149H361.128L357.814 50.1004H339.04L335.726 60.7149H316.867ZM342.693 35.9194H354.076L348.469 18.9363L342.693 35.9194Z" fill="white"/>
          <path d="M386.877 60.7149V1.27374H404.037V45.0904H426.55V60.7149H386.877Z" fill="white"/>
          <path d="M454.159 60.7149V1.27374H479.561C484.12 1.27374 488.183 2.08044 491.751 3.69384C495.319 5.30725 498.123 7.65659 500.162 10.7419C502.229 13.7988 503.263 17.4927 503.263 21.8234C503.263 26.1824 502.314 29.8904 500.417 32.9474C498.519 36.0044 495.773 38.3395 492.176 39.9529C488.58 41.5663 484.233 42.3731 479.136 42.3731H471.32V60.7149H454.159ZM471.32 28.1921H478.456C480.835 28.1921 482.704 27.7534 484.063 26.8759C485.422 25.9701 486.102 24.3709 486.102 22.0781C486.102 19.7005 485.394 18.0729 483.978 17.1955C482.562 16.318 480.75 15.8793 478.541 15.8793H471.32V28.1921Z" fill="white"/>
          <path d="M502.122 60.7149L523.786 1.27374H544.175L565.753 60.7149H546.383L543.07 50.1004H524.295L520.982 60.7149H502.122ZM527.948 35.9194H539.332L533.725 18.9363L527.948 35.9194Z" fill="white"/>
          <path d="M572.132 60.7149V1.27374H597.364C602.008 1.27374 606.057 1.99552 609.512 3.43909C612.995 4.88266 615.685 6.99141 617.583 9.76533C619.508 12.5109 620.471 15.851 620.471 19.7854C620.471 22.6726 619.961 25.2059 618.942 27.3854C617.922 29.5649 616.563 31.4047 614.864 32.9049C613.165 34.4051 611.296 35.5798 609.257 36.4289L623.954 60.7149H603.735L592.606 40.2502H589.293V60.7149H572.132ZM589.293 26.7485H595.58C597.93 26.7485 599.771 26.3239 601.102 25.4748C602.461 24.6256 603.14 23.1254 603.14 20.9742C603.14 18.7947 602.461 17.2945 601.102 16.4737C599.771 15.6245 597.958 15.2 595.665 15.2H589.293V26.7485Z" fill="white"/>
          <path d="M641.722 60.7149V16.8983H626.43V1.27374H674.259V16.8983H658.968V60.7149H641.722Z" fill="white"/>
          <path d="M681.892 60.7149V1.27374H699.053L719.866 33.2021V1.27374H736.432V60.7149H719.272L698.458 28.277V60.7149H681.892Z" fill="white"/>
          <path d="M749.424 60.7149V1.27374H793.515V16.0491H766.584V23.7765H788.672V37.2781H766.584V45.7697H793.854V60.7149H749.424Z" fill="white"/>
          <path d="M804.677 60.7149V1.27374H829.908C834.552 1.27374 838.602 1.99552 842.056 3.43909C845.54 4.88266 848.23 6.99141 850.127 9.76533C852.053 12.5109 853.016 15.851 853.016 19.7854C853.016 22.6726 852.506 25.2059 851.486 27.3854C850.467 29.5649 849.108 31.4047 847.409 32.9049C845.71 34.4051 843.841 35.5798 841.802 36.4289L856.499 60.7149H836.28L825.151 40.2502H821.838V60.7149H804.677ZM821.838 26.7485H828.124C830.475 26.7485 832.315 26.3239 833.646 25.4748C835.005 24.6256 835.685 23.1254 835.685 20.9742C835.685 18.7947 835.005 17.2945 833.646 16.4737C832.315 15.6245 830.503 15.2 828.209 15.2H821.838V26.7485Z" fill="white"/>
          <path d="M18.0913 149.491V105.674H2.79961V90.0495H50.6286V105.674H35.3369V149.491H18.0913Z" fill="white"/>
          <path d="M84.8936 150.764C80.2494 150.764 75.9734 149.986 72.0655 148.429C68.1577 146.844 64.7595 144.65 61.8711 141.848C59.011 139.046 56.788 135.763 55.2022 131.998C53.6447 128.233 52.866 124.157 52.866 119.77C52.866 115.354 53.6447 111.264 55.2022 107.5C56.788 103.735 59.011 100.452 61.8711 97.6495C64.7595 94.8472 68.1577 92.6677 72.0655 91.1109C75.9734 89.5541 80.2494 88.7757 84.8936 88.7757C89.5377 88.7757 93.7996 89.5683 97.6791 91.1534C101.587 92.7385 104.971 94.9463 107.831 97.7768C110.72 100.607 112.957 103.905 114.542 107.67C116.128 111.406 116.921 115.439 116.921 119.77C116.921 124.157 116.128 128.233 114.542 131.998C112.957 135.763 110.72 139.046 107.831 141.848C104.971 144.65 101.587 146.844 97.6791 148.429C93.7996 149.986 89.5377 150.764 84.8936 150.764ZM84.8936 134.715C86.9608 134.715 88.8439 134.376 90.543 133.696C92.2704 132.989 93.7571 131.984 95.0031 130.682C96.2491 129.38 97.2119 127.809 97.8915 125.969C98.5711 124.129 98.9109 122.063 98.9109 119.77C98.9109 116.713 98.3163 114.067 97.1269 111.83C95.9375 109.594 94.281 107.868 92.1571 106.651C90.0616 105.433 87.6404 104.825 84.8936 104.825C82.8263 104.825 80.929 105.179 79.2016 105.886C77.5026 106.566 76.03 107.556 74.7841 108.858C73.5381 110.16 72.5753 111.731 71.8956 113.571C71.216 115.411 70.8762 117.477 70.8762 119.77C70.8762 122.827 71.4708 125.474 72.6602 127.71C73.8496 129.946 75.492 131.672 77.5875 132.89C79.7114 134.107 82.1467 134.715 84.8936 134.715Z" fill="white"/>
          <path d="M175.031 150.764C170.5 150.764 166.21 150.071 162.16 148.684C158.111 147.269 154.515 145.231 151.371 142.57C148.256 139.909 145.793 136.668 143.98 132.847C142.196 128.998 141.304 124.639 141.304 119.77C141.304 114.958 142.168 110.642 143.895 106.82C145.623 102.999 148.016 99.7441 151.074 97.0551C154.132 94.366 157.658 92.3139 161.651 90.8986C165.644 89.4834 169.905 88.7757 174.436 88.7757C176.503 88.7757 178.712 88.9314 181.063 89.2428C183.413 89.5541 185.848 90.1344 188.369 90.9836C190.917 91.8327 193.466 93.064 196.015 94.6774L188.963 107.882C187.009 106.608 184.956 105.674 182.804 105.08C180.68 104.457 178.344 104.146 175.795 104.146C173.134 104.146 170.755 104.513 168.659 105.249C166.564 105.957 164.794 106.99 163.35 108.349C161.934 109.708 160.844 111.363 160.079 113.316C159.343 115.27 158.975 117.477 158.975 119.94C158.975 123.563 159.753 126.563 161.311 128.941C162.897 131.319 164.992 133.088 167.597 134.248C170.203 135.409 173.049 135.989 176.135 135.989C177.041 135.989 177.99 135.932 178.981 135.819C179.972 135.678 180.949 135.508 181.912 135.31V127.328H170.613V113.911H196.779V145.924C194.853 146.915 192.631 147.778 190.11 148.514C187.59 149.25 185.013 149.802 182.379 150.17C179.746 150.566 177.296 150.764 175.031 150.764Z" fill="white"/>
          <path d="M208.921 149.491V90.0495H234.153C238.797 90.0495 242.846 90.7713 246.301 92.2148C249.784 93.6584 252.474 95.7672 254.372 98.5411C256.297 101.287 257.26 104.627 257.26 108.561C257.26 111.448 256.751 113.982 255.731 116.161C254.712 118.341 253.352 120.18 251.653 121.681C249.954 123.181 248.085 124.356 246.046 125.205L260.743 149.491H240.524L229.395 129.026H226.082V149.491H208.921ZM226.082 115.524H232.369C234.719 115.524 236.56 115.1 237.891 114.251C239.25 113.401 239.93 111.901 239.93 109.75C239.93 107.57 239.25 106.07 237.891 105.249C236.56 104.4 234.747 103.976 232.454 103.976H226.082V115.524Z" fill="white"/>
          <path d="M296.117 150.764C291.473 150.764 287.196 149.986 283.289 148.429C279.381 146.844 275.983 144.65 273.094 141.848C270.234 139.046 268.011 135.763 266.425 131.998C264.868 128.233 264.089 124.157 264.089 119.77C264.089 115.354 264.868 111.264 266.425 107.5C268.011 103.735 270.234 100.452 273.094 97.6495C275.983 94.8472 279.381 92.6677 283.289 91.1109C287.196 89.5541 291.473 88.7757 296.117 88.7757C300.761 88.7757 305.023 89.5683 308.902 91.1534C312.81 92.7385 316.194 94.9463 319.054 97.7768C321.943 100.607 324.18 103.905 325.766 107.67C327.351 111.406 328.144 115.439 328.144 119.77C328.144 124.157 327.351 128.233 325.766 131.998C324.18 135.763 321.943 139.046 319.054 141.848C316.194 144.65 312.81 146.844 308.902 148.429C305.023 149.986 300.761 150.764 296.117 150.764ZM296.117 134.715C298.184 134.715 300.067 134.376 301.766 133.696C303.493 132.989 304.98 131.984 306.226 130.682C307.472 129.38 308.435 127.809 309.115 125.969C309.794 124.129 310.134 122.063 310.134 119.77C310.134 116.713 309.539 114.067 308.35 111.83C307.161 109.594 305.504 107.868 303.38 106.651C301.285 105.433 298.864 104.825 296.117 104.825C294.049 104.825 292.152 105.179 290.425 105.886C288.726 106.566 287.253 107.556 286.007 108.858C284.761 110.16 283.798 111.731 283.119 113.571C282.439 115.411 282.099 117.477 282.099 119.77C282.099 122.827 282.694 125.474 283.883 127.71C285.073 129.946 286.715 131.672 288.811 132.89C290.934 134.107 293.37 134.715 296.117 134.715Z" fill="white"/>
          <path d="M348.673 149.491L330.492 90.0495H348.163L356.658 123.252L366.088 90.0495H382.994L392.424 123.252L400.919 90.0495H418.165L399.985 149.491H383.589L374.244 115.27L364.899 149.491H348.673Z" fill="white"/>
          <path d="M457.291 149.491V125.205L436.562 90.0495H455.677L466.211 108.816L476.66 90.0495H495.265L474.536 125.375V149.491H457.291Z" fill="white"/>
          <path d="M525.757 150.764C521.113 150.764 516.837 149.986 512.929 148.429C509.022 146.844 505.623 144.65 502.735 141.848C499.875 139.046 497.652 135.763 496.066 131.998C494.509 128.233 493.73 124.157 493.73 119.77C493.73 115.354 494.509 111.264 496.066 107.5C497.652 103.735 499.875 100.452 502.735 97.6495C505.623 94.8472 509.022 92.6677 512.929 91.1109C516.837 89.5541 521.113 88.7757 525.757 88.7757C530.402 88.7757 534.663 89.5683 538.543 91.1534C542.451 92.7385 545.835 94.9463 548.695 97.7768C551.583 100.607 553.821 103.905 555.406 107.67C556.992 111.406 557.785 115.439 557.785 119.77C557.785 124.157 556.992 128.233 555.406 131.998C553.821 135.763 551.583 139.046 548.695 141.848C545.835 144.65 542.451 146.844 538.543 148.429C534.663 149.986 530.402 150.764 525.757 150.764ZM525.757 134.715C527.825 134.715 529.708 134.376 531.407 133.696C533.134 132.989 534.621 131.984 535.867 130.682C537.113 129.38 538.076 127.809 538.755 125.969C539.435 124.129 539.775 122.063 539.775 119.77C539.775 116.713 539.18 114.067 537.991 111.83C536.801 109.594 535.145 107.868 533.021 106.651C530.926 105.433 528.504 104.825 525.757 104.825C523.69 104.825 521.793 105.179 520.066 105.886C518.366 106.566 516.894 107.556 515.648 108.858C514.402 110.16 513.439 111.731 512.76 113.571C512.08 115.411 511.74 117.477 511.74 119.77C511.74 122.827 512.335 125.474 513.524 127.71C514.713 129.946 516.356 131.672 518.451 132.89C520.575 134.107 523.011 134.715 525.757 134.715Z" fill="white"/>
          <path d="M593.278 150.764C587.529 150.764 582.687 149.646 578.751 147.41C574.843 145.174 571.884 142.06 569.873 138.069C567.862 134.078 566.857 129.45 566.857 124.186V90.0495H584.018V125.12C584.018 128.375 584.811 130.795 586.397 132.38C588.011 133.937 590.304 134.715 593.278 134.715C596.223 134.715 598.488 133.937 600.074 132.38C601.66 130.795 602.453 128.375 602.453 125.12V90.0495H619.698V124.186C619.698 129.422 618.693 134.036 616.683 138.027C614.672 142.018 611.699 145.146 607.762 147.41C603.855 149.646 599.026 150.764 593.278 150.764Z" fill="white"/>
          <path d="M632.363 149.491V90.0495H657.594C662.239 90.0495 666.288 90.7713 669.743 92.2148C673.226 93.6584 675.916 95.7672 677.814 98.5411C679.739 101.287 680.702 104.627 680.702 108.561C680.702 111.448 680.192 113.982 679.173 116.161C678.153 118.341 676.794 120.18 675.095 121.681C673.396 123.181 671.527 124.356 669.488 125.205L684.185 149.491H663.966L652.837 129.026H649.524V149.491H632.363ZM649.524 115.524H655.811C658.161 115.524 660.002 115.1 661.332 114.251C662.692 113.401 663.371 111.901 663.371 109.75C663.371 107.57 662.692 106.07 661.332 105.249C660.002 104.4 658.189 103.976 655.895 103.976H649.524V115.524Z" fill="white"/>
          <path d="M36.5263 239.54C31.8821 239.54 27.6061 238.762 23.6983 237.205C19.7904 235.62 16.3922 233.426 13.5038 230.624C10.6437 227.822 8.42073 224.538 6.83492 220.774C5.27744 217.009 4.49869 212.933 4.49869 208.546C4.49869 204.13 5.27744 200.04 6.83492 196.275C8.42073 192.511 10.6437 189.227 13.5038 186.425C16.3922 183.623 19.7904 181.443 23.6983 179.887C27.6061 178.33 31.8821 177.551 36.5263 177.551C41.1704 177.551 45.4323 178.344 49.3118 179.929C53.2197 181.514 56.6037 183.722 59.4638 186.553C62.3523 189.383 64.5894 192.681 66.1752 196.445C67.761 200.182 68.5539 204.215 68.5539 208.546C68.5539 212.933 67.761 217.009 66.1752 220.774C64.5894 224.538 62.3523 227.822 59.4638 230.624C56.6037 233.426 53.2197 235.62 49.3118 237.205C45.4323 238.762 41.1704 239.54 36.5263 239.54ZM36.5263 223.491C38.5935 223.491 40.4766 223.151 42.1757 222.472C43.9031 221.764 45.3898 220.76 46.6358 219.458C47.8818 218.155 48.8446 216.585 49.5242 214.745C50.2039 212.905 50.5437 210.839 50.5437 208.546C50.5437 205.489 49.949 202.842 48.7596 200.606C47.5703 198.37 45.9137 196.643 43.7898 195.426C41.6943 194.209 39.2731 193.601 36.5263 193.601C34.4591 193.601 32.5618 193.954 30.8344 194.662C29.1353 195.341 27.6628 196.332 26.4168 197.634C25.1708 198.936 24.208 200.507 23.5284 202.347C22.8487 204.187 22.5089 206.253 22.5089 208.546C22.5089 211.603 23.1036 214.249 24.2929 216.485C25.4823 218.722 27.1247 220.448 29.2203 221.665C31.3441 222.882 33.7794 223.491 36.5263 223.491Z" fill="white"/>
          <path d="M77.9234 238.266V178.825H95.084L115.898 210.754V178.825H132.464V238.266H115.303L94.4894 205.828V238.266H77.9234Z" fill="white"/>
          <path d="M145.455 238.266V178.825H162.616V222.642H185.128V238.266H145.455Z" fill="white"/>
          <path d="M194.071 238.266V178.825H211.232V238.266H194.071Z" fill="white"/>
          <path d="M224.27 238.266V178.825H241.43L262.244 210.754V178.825H278.81V238.266H261.649L240.836 205.828V238.266H224.27Z" fill="white"/>
          <path d="M291.801 238.266V178.825H335.892V193.601H308.962V201.328H331.05V214.83H308.962V223.321H336.232V238.266H291.801Z" fill="white"/>
          <path d="M366.846 238.266L379.419 178.825H401.04C407.27 178.825 412.112 180.028 415.567 182.434C419.05 184.84 420.792 188.237 420.792 192.624C420.792 194.832 420.31 196.827 419.348 198.611C418.385 200.366 417.054 201.894 415.355 203.196C413.656 204.498 411.716 205.531 409.535 206.296C412.084 207.541 414.024 209.296 415.355 211.56C416.686 213.796 417.351 216.344 417.351 219.203C417.351 223.166 416.289 226.576 414.165 229.435C412.042 232.266 409.196 234.445 405.628 235.974C402.059 237.502 398.109 238.266 393.776 238.266H366.846ZM384.814 226.93H392.799C395.348 226.93 397.344 226.222 398.789 224.807C400.261 223.392 400.998 221.538 400.998 219.245C400.998 217.292 400.36 215.834 399.086 214.872C397.84 213.881 395.985 213.386 393.522 213.386H387.745L384.814 226.93ZM389.954 202.856H397.769C399.242 202.856 400.53 202.517 401.635 201.837C402.767 201.158 403.631 200.281 404.226 199.205C404.849 198.101 405.16 196.912 405.16 195.639C405.16 193.799 404.523 192.426 403.249 191.52C401.974 190.614 400.063 190.162 397.514 190.162H392.715L389.954 202.856Z" fill="url(#paint0_diamond_6_103)"/>
          <path d="M451.453 239.54C445.818 239.54 441.103 238.493 437.309 236.398C433.514 234.304 430.838 231.303 429.281 227.397C427.723 223.491 427.525 218.821 428.686 213.386L436.034 178.825H451.666L444.147 214.108C443.383 217.646 443.708 220.335 445.124 222.175C446.54 223.986 449.061 224.892 452.685 224.892C455.687 224.892 458.236 224.057 460.331 222.387C462.427 220.689 463.871 217.929 464.664 214.108L472.182 178.825H487.899L480.593 213.386C479.46 218.736 477.605 223.378 475.028 227.312C472.451 231.218 469.195 234.233 465.258 236.356C461.322 238.479 456.721 239.54 451.453 239.54Z" fill="url(#paint1_diamond_6_103)"/>
          <path d="M511.955 239.54C507.028 239.54 502.313 238.748 497.81 237.162C493.336 235.549 489.711 233.313 486.936 230.454L496.111 219.5C497.272 220.859 498.801 222.076 500.699 223.151C502.624 224.199 504.692 225.019 506.9 225.614C509.109 226.18 511.247 226.463 513.314 226.463C515.75 226.463 517.746 226.053 519.304 225.232C520.89 224.411 521.682 223.208 521.682 221.623C521.682 220.462 521.13 219.429 520.026 218.523C518.921 217.589 516.996 216.627 514.249 215.636L509.874 214.108C504.89 212.296 501.265 210.046 499 207.357C496.763 204.64 495.644 201.597 495.644 198.229C495.644 195.143 496.295 192.341 497.598 189.822C498.929 187.274 500.727 185.081 502.993 183.241C505.286 181.401 507.892 180 510.808 179.038C513.753 178.047 516.854 177.551 520.111 177.551C525.038 177.551 529.555 178.344 533.661 179.929C537.767 181.486 540.924 183.552 543.133 186.128L533.618 196.785C532.684 195.596 531.452 194.549 529.923 193.643C528.422 192.709 526.737 191.987 524.868 191.478C522.999 190.94 521.116 190.671 519.219 190.671C517.605 190.671 516.175 190.883 514.929 191.308C513.683 191.732 512.706 192.327 511.998 193.091C511.29 193.855 510.936 194.761 510.936 195.808C510.936 196.516 511.162 197.153 511.615 197.719C512.097 198.257 512.861 198.809 513.909 199.375C514.985 199.913 516.415 200.521 518.199 201.201L522.787 202.941C527.799 204.838 531.48 207.031 533.831 209.522C536.181 212.013 537.356 215.014 537.356 218.523C537.356 222.798 536.21 226.506 533.916 229.647C531.622 232.789 528.549 235.224 524.698 236.95C520.875 238.677 516.628 239.54 511.955 239.54Z" fill="url(#paint2_diamond_6_103)"/>
          <path d="M542.893 238.266L555.466 178.825H571.098L558.524 238.266H542.893Z" fill="url(#paint3_diamond_6_103)"/>
          <path d="M572.179 238.266L584.752 178.825H600.086L615.505 213.598L622.939 178.825H638.061L625.487 238.266H610.238L594.819 202.984L587.301 238.266H572.179Z" fill="url(#paint4_diamond_6_103)"/>
          <path d="M639.13 238.266L651.703 178.825H694.647L691.801 192.327H664.446L662.492 201.71H685.132L682.499 214.108H659.858L657.607 224.595H685.26L682.414 238.266H639.13Z" fill="url(#paint5_diamond_6_103)"/>
          <path d="M717.122 239.54C712.195 239.54 707.48 238.748 702.977 237.162C698.503 235.549 694.878 233.313 692.103 230.454L701.278 219.5C702.439 220.859 703.968 222.076 705.866 223.151C707.791 224.199 709.858 225.019 712.067 225.614C714.276 226.18 716.414 226.463 718.481 226.463C720.917 226.463 722.913 226.053 724.471 225.232C726.056 224.411 726.849 223.208 726.849 221.623C726.849 220.462 726.297 219.429 725.193 218.523C724.088 217.589 722.163 216.627 719.416 215.636L715.041 214.108C710.057 212.296 706.432 210.046 704.167 207.357C701.929 204.64 700.811 201.597 700.811 198.229C700.811 195.143 701.462 192.341 702.765 189.822C704.096 187.274 705.894 185.081 708.159 183.241C710.453 181.401 713.058 180 715.975 179.038C718.92 178.047 722.021 177.551 725.278 177.551C730.205 177.551 734.722 178.344 738.828 179.929C742.934 181.486 746.091 183.552 748.3 186.128L738.785 196.785C737.851 195.596 736.619 194.549 735.09 193.643C733.589 192.709 731.904 191.987 730.035 191.478C728.166 190.94 726.283 190.671 724.386 190.671C722.771 190.671 721.341 190.883 720.095 191.308C718.849 191.732 717.872 192.327 717.165 193.091C716.457 193.855 716.103 194.761 716.103 195.808C716.103 196.516 716.329 197.153 716.782 197.719C717.264 198.257 718.028 198.809 719.076 199.375C720.152 199.913 721.582 200.521 723.366 201.201L727.954 202.941C732.966 204.838 736.647 207.031 738.998 209.522C741.348 212.013 742.523 215.014 742.523 218.523C742.523 222.798 741.376 226.506 739.083 229.647C736.789 232.789 733.716 235.224 729.865 236.95C726.042 238.677 721.794 239.54 717.122 239.54Z" fill="url(#paint6_diamond_6_103)"/>
          <path d="M771.38 239.54C766.452 239.54 761.737 238.748 757.235 237.162C752.761 235.549 749.136 233.313 746.361 230.454L755.536 219.5C756.697 220.859 758.226 222.076 760.123 223.151C762.049 224.199 764.116 225.019 766.325 225.614C768.534 226.18 770.672 226.463 772.739 226.463C775.174 226.463 777.171 226.053 778.728 225.232C780.314 224.411 781.107 223.208 781.107 221.623C781.107 220.462 780.555 219.429 779.45 218.523C778.346 217.589 776.42 216.627 773.673 215.636L769.298 214.108C764.314 212.296 760.69 210.046 758.424 207.357C756.187 204.64 755.069 201.597 755.069 198.229C755.069 195.143 755.72 192.341 757.022 189.822C758.353 187.274 760.152 185.081 762.417 183.241C764.711 181.401 767.316 180 770.233 179.038C773.178 178.047 776.279 177.551 779.535 177.551C784.463 177.551 788.979 178.344 793.085 179.929C797.191 181.486 800.349 183.552 802.558 186.128L793.043 196.785C792.108 195.596 790.877 194.549 789.347 193.643C787.847 192.709 786.162 191.987 784.293 191.478C782.424 190.94 780.54 190.671 778.643 190.671C777.029 190.671 775.599 190.883 774.353 191.308C773.107 191.732 772.13 192.327 771.422 193.091C770.714 193.855 770.36 194.761 770.36 195.808C770.36 196.516 770.587 197.153 771.04 197.719C771.521 198.257 772.286 198.809 773.334 199.375C774.41 199.913 775.84 200.521 777.624 201.201L782.211 202.941C787.224 204.838 790.905 207.031 793.255 209.522C795.606 212.013 796.781 215.014 796.781 218.523C796.781 222.798 795.634 226.506 793.34 229.647C791.046 232.789 787.974 235.224 784.123 236.95C780.3 238.677 776.052 239.54 771.38 239.54Z" fill="url(#paint7_diamond_6_103)"/>
          <path d="M0 324.726L12.5732 265.285H34.1939C40.4239 265.285 45.2662 266.488 48.721 268.894C52.2041 271.3 53.9457 274.697 53.9457 279.084C53.9457 281.292 53.4643 283.287 52.5015 285.07C51.5387 286.825 50.2077 288.354 48.5086 289.656C46.8096 290.958 44.8698 291.991 42.6893 292.755C45.2379 294.001 47.1777 295.756 48.5086 298.02C49.8396 300.256 50.5051 302.804 50.5051 305.663C50.5051 309.625 49.4431 313.036 47.3193 315.895C45.1954 318.725 42.3495 320.905 38.7814 322.434C35.2134 323.962 31.263 324.726 26.9304 324.726H0ZM17.9677 313.39H25.9534C28.502 313.39 30.4984 312.682 31.9426 311.267C33.4152 309.852 34.1514 307.998 34.1514 305.705C34.1514 303.752 33.5143 302.294 32.24 301.332C30.994 300.341 29.1392 299.846 26.6755 299.846H20.8986L17.9677 313.39ZM23.1074 289.316H30.9232C32.3957 289.316 33.6842 288.977 34.7886 288.297C35.9213 287.618 36.785 286.74 37.3797 285.665C38.0027 284.561 38.3142 283.372 38.3142 282.098C38.3142 280.259 37.677 278.886 36.4027 277.98C35.1284 277.074 33.2169 276.621 30.6683 276.621H25.8684L23.1074 289.316Z" fill="url(#paint8_diamond_6_103)"/>
          <path d="M56.9124 324.726L69.4856 265.285H91.4462C96.0337 265.285 99.9416 265.95 103.17 267.281C106.398 268.583 108.862 270.465 110.561 272.928C112.288 275.362 113.152 278.263 113.152 281.631C113.152 284.802 112.458 287.689 111.07 290.293C109.683 292.897 107.771 295.119 105.336 296.959C102.901 298.77 100.111 300.086 96.9682 300.907L107.545 324.726H89.6197L81.2942 303.54H77.004L72.5439 324.726H56.9124ZM79.6801 291.184H88.6427C91.163 291.184 93.1877 290.548 94.7169 289.274C96.2744 287.972 97.0531 286.132 97.0531 283.754C97.0531 281.631 96.3169 280.145 94.8443 279.296C93.3718 278.447 91.2621 278.022 88.5153 278.022H82.4835L79.6801 291.184Z" fill="url(#paint9_diamond_6_103)"/>
          <path d="M109.311 324.726L144.185 265.285H162.492L171.71 324.726H154.464L153.062 313.39H133.395L127.236 324.726H109.311ZM139.512 300.398H152.425L149.919 282.014L139.512 300.398Z" fill="url(#paint10_diamond_6_103)"/>
          <path d="M178.702 324.726L191.275 265.285H206.609L222.028 300.058L229.462 265.285H244.583L232.01 324.726H216.761L201.342 289.444L193.823 324.726H178.702Z" fill="url(#paint11_diamond_6_103)"/>
          <path d="M245.653 324.726L258.226 265.285H274.155C280.073 265.285 285.326 266.29 289.914 268.3C294.501 270.309 298.097 273.239 300.703 277.088C303.336 280.938 304.653 285.651 304.653 291.227C304.653 296.067 303.804 300.525 302.104 304.601C300.434 308.677 298.069 312.229 295.011 315.258C291.952 318.258 288.314 320.594 284.094 322.264C279.903 323.905 275.287 324.726 270.247 324.726H245.653ZM264.385 310.163H271.266C273.9 310.163 276.25 309.739 278.317 308.889C280.413 308.012 282.183 306.795 283.627 305.238C285.1 303.653 286.218 301.785 286.983 299.634C287.776 297.482 288.172 295.133 288.172 292.586C288.172 289.783 287.563 287.434 286.346 285.538C285.128 283.641 283.415 282.212 281.206 281.249C279.025 280.287 276.463 279.806 273.517 279.806H270.757L264.385 310.163Z" fill="url(#paint12_diamond_6_103)"/>
          <path d="M440.366 326C435.439 326 430.724 325.207 426.221 323.622C421.747 322.009 418.122 319.773 415.347 316.914L424.522 305.96C425.683 307.318 427.212 308.536 429.11 309.611C431.035 310.659 433.102 311.479 435.311 312.074C437.52 312.64 439.658 312.923 441.725 312.923C444.161 312.923 446.157 312.513 447.714 311.692C449.3 310.871 450.093 309.668 450.093 308.083C450.093 306.922 449.541 305.889 448.437 304.983C447.332 304.049 445.406 303.087 442.66 302.096L438.285 300.568C433.301 298.756 429.676 296.506 427.41 293.817C425.173 291.1 424.055 288.057 424.055 284.688C424.055 281.603 424.706 278.801 426.009 276.282C427.34 273.734 429.138 271.541 431.403 269.701C433.697 267.861 436.302 266.46 439.219 265.497C442.164 264.507 445.265 264.011 448.521 264.011C453.449 264.011 457.965 264.804 462.072 266.389C466.178 267.946 469.335 270.012 471.544 272.588L462.029 283.245C461.095 282.056 459.863 281.009 458.334 280.103C456.833 279.169 455.148 278.447 453.279 277.938C451.41 277.4 449.527 277.131 447.629 277.131C446.015 277.131 444.585 277.343 443.339 277.768C442.093 278.192 441.116 278.787 440.408 279.551C439.7 280.315 439.346 281.221 439.346 282.268C439.346 282.976 439.573 283.613 440.026 284.179C440.507 284.717 441.272 285.269 442.32 285.835C443.396 286.373 444.826 286.981 446.61 287.66L451.198 289.401C456.21 291.298 459.891 293.491 462.242 295.982C464.592 298.473 465.767 301.473 465.767 304.983C465.767 309.257 464.62 312.965 462.326 316.107C460.033 319.249 456.96 321.683 453.109 323.41C449.286 325.137 445.038 326 440.366 326Z" fill="url(#paint13_diamond_6_103)"/>
          <path d="M481.498 324.726L491.055 279.509H475.466L478.482 265.285H525.377L522.361 279.509H506.772L497.215 324.726H481.498Z" fill="url(#paint14_diamond_6_103)"/>
          <path d="M520.998 324.726L533.572 265.285H555.532C560.12 265.285 564.027 265.95 567.256 267.281C570.484 268.583 572.948 270.465 574.647 272.928C576.374 275.362 577.238 278.263 577.238 281.631C577.238 284.802 576.544 287.689 575.156 290.293C573.769 292.897 571.857 295.119 569.422 296.959C566.987 298.77 564.197 300.086 561.054 300.907L571.631 324.726H553.706L545.38 303.54H541.09L536.63 324.726H520.998ZM543.766 291.184H552.729C555.249 291.184 557.274 290.548 558.803 289.274C560.36 287.972 561.139 286.132 561.139 283.754C561.139 281.631 560.403 280.145 558.93 279.296C557.458 278.447 555.348 278.022 552.601 278.022H546.569L543.766 291.184Z" fill="url(#paint15_diamond_6_103)"/>
          <path d="M573.397 324.726L608.271 265.285H626.578L635.796 324.726H618.55L617.148 313.39H597.481L591.322 324.726H573.397ZM603.598 300.398H616.511L614.005 282.014L603.598 300.398Z" fill="url(#paint16_diamond_6_103)"/>
          <path d="M647.921 324.726L657.479 279.509H641.89L644.906 265.285H691.8L688.784 279.509H673.195L663.638 324.726H647.921Z" fill="url(#paint17_diamond_6_103)"/>
          <path d="M687.422 324.726L699.995 265.285H742.939L740.093 278.787H712.738L710.784 288.17H733.424L730.791 300.568H708.15L705.899 311.055H733.552L730.706 324.726H687.422Z" fill="url(#paint18_diamond_6_103)"/>
          <path d="M775.736 326C771.403 326 767.354 325.377 763.587 324.132C759.849 322.858 756.55 321.032 753.69 318.655C750.858 316.249 748.65 313.362 747.064 309.993C745.478 306.625 744.685 302.818 744.685 298.572C744.685 293.619 745.605 289.047 747.446 284.858C749.287 280.641 751.85 276.975 755.134 273.862C758.419 270.748 762.242 268.328 766.603 266.601C770.964 264.875 775.651 264.011 780.663 264.011C782.957 264.011 785.378 264.209 787.927 264.606C790.504 264.974 793.109 265.681 795.742 266.729C798.404 267.748 800.981 269.262 803.473 271.272L794.808 282.65C792.854 281.094 790.688 279.947 788.309 279.211C785.93 278.447 783.283 278.065 780.366 278.065C777.392 278.065 774.702 278.546 772.295 279.509C769.888 280.471 767.835 281.83 766.136 283.584C764.437 285.339 763.134 287.406 762.228 289.783C761.322 292.161 760.869 294.779 760.869 297.638C760.869 300.865 761.619 303.582 763.12 305.79C764.649 307.969 766.702 309.625 769.279 310.758C771.885 311.861 774.801 312.413 778.03 312.413C778.879 312.413 779.686 312.371 780.451 312.286C781.244 312.201 782.022 312.088 782.787 311.946L784.868 302.266H773.102L775.778 289.953H801.094L794.043 322.858C792.288 323.537 790.348 324.118 788.224 324.599C786.128 325.052 784.005 325.391 781.852 325.618C779.7 325.873 777.661 326 775.736 326Z" fill="url(#paint19_diamond_6_103)"/>
          <path d="M817.727 324.726L822.867 300.143L809.954 265.285H826.732L833.613 284.986L849.075 265.285H867L838.583 300.44L833.401 324.726H817.727Z" fill="url(#paint20_diamond_6_103)"/>
          <path d="M349.705 325.958C345.967 325.958 342.696 325.278 339.893 323.92C337.117 322.561 334.965 320.679 333.436 318.273C331.907 315.867 331.142 313.121 331.142 310.036C331.142 306.95 331.808 304.148 333.139 301.629C334.47 299.082 336.296 296.86 338.618 294.963C340.94 293.067 343.602 291.538 346.604 290.378C345.896 289.274 345.315 288.212 344.862 287.193C344.438 286.174 344.112 285.17 343.885 284.179C343.687 283.16 343.588 282.127 343.588 281.079C343.588 277.881 344.466 274.994 346.222 272.418C348.006 269.814 350.356 267.762 353.273 266.262C356.218 264.761 359.432 264.011 362.915 264.011C366.115 264.011 368.947 264.577 371.41 265.71C373.902 266.814 375.842 268.356 377.23 270.338C378.646 272.319 379.354 274.612 379.354 277.216C379.354 280.697 378.193 283.783 375.87 286.472C373.577 289.161 370.023 291.609 365.209 293.817L371.368 302.054C373.237 300.157 374.964 298.091 376.55 295.855C378.136 293.59 379.736 290.788 381.35 287.448L392.776 292.161C391.842 294.171 390.539 296.364 388.868 298.742C387.226 301.12 385.428 303.427 383.474 305.663C381.52 307.899 379.637 309.809 377.824 311.394L387.212 324.726H370.986L367.545 320.013C364.94 321.91 362.051 323.382 358.88 324.429C355.708 325.448 352.65 325.958 349.705 325.958ZM352.296 313.687C353.768 313.687 355.269 313.418 356.798 312.88C358.328 312.343 359.701 311.578 360.919 310.588L352.678 299.251C350.554 300.016 348.77 301.247 347.326 302.945C345.882 304.615 345.16 306.342 345.16 308.125C345.16 309.795 345.811 311.14 347.114 312.159C348.416 313.178 350.144 313.687 352.296 313.687ZM359.092 286.217C361.414 285.538 363.269 284.603 364.657 283.415C366.044 282.198 366.738 280.796 366.738 279.211C366.738 277.909 366.313 276.876 365.464 276.112C364.614 275.319 363.467 274.923 362.023 274.923C360.437 274.923 359.092 275.404 357.988 276.367C356.883 277.301 356.331 278.546 356.331 280.103C356.331 280.98 356.558 281.957 357.011 283.033C357.492 284.108 358.186 285.17 359.092 286.217Z" fill="white"/>
          <path d="M811.309 254.273L808.208 248.032C811.267 246.786 813.985 245.371 816.364 243.786C818.743 242.201 820.498 240.531 821.631 238.776C820.017 238.691 818.573 238.337 817.298 237.714C816.024 237.063 815.019 236.186 814.282 235.082C813.574 233.95 813.22 232.634 813.22 231.133C813.22 228.473 814.141 226.265 815.982 224.51C817.822 222.755 820.116 221.878 822.863 221.878C825.695 221.878 827.974 222.727 829.702 224.425C831.429 226.123 832.293 228.402 832.293 231.261C832.293 234.573 831.302 237.63 829.319 240.432C827.365 243.262 824.788 245.824 821.589 248.117C818.417 250.409 814.99 252.462 811.309 254.273Z" fill="white"/>
          <defs>
          <radialGradient id="paint0_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint1_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint2_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint3_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint4_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint5_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint6_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint7_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint8_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint9_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint10_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint11_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint12_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint13_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint14_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint15_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint16_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint17_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint18_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint19_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          <radialGradient id="paint20_diamond_6_103" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(413.87 164.173) rotate(-169.004) scale(454.94 839.069)">
          <stop stop-color="#FF6421"/>
          <stop offset="0.5" stop-color="#D233A5"/>
          <stop offset="1" stop-color="#36FFFF"/>
          </radialGradient>
          </defs>
      </svg>

      <svg class="mobile" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 392.1 427.3">
          <defs>
            <style>
              .cls-1 {
                fill: url(#radial-gradient-7);
              }
        
              .cls-2 {
                fill: url(#radial-gradient-6);
              }
        
              .cls-3 {
                fill: url(#radial-gradient-5);
              }
        
              .cls-4 {
                fill: url(#radial-gradient-4);
              }
        
              .cls-5 {
                fill: url(#radial-gradient-9);
              }
        
              .cls-6 {
                fill: url(#radial-gradient-8);
              }
        
              .cls-7 {
                fill: url(#radial-gradient-3);
              }
        
              .cls-8 {
                fill: url(#radial-gradient-2);
              }
        
              .cls-9 {
                fill: url(#radial-gradient);
              }
        
              .cls-10 {
                fill: url(#radial-gradient-16);
              }
        
              .cls-11 {
                fill: url(#radial-gradient-12);
              }
        
              .cls-12 {
                fill: url(#radial-gradient-14);
              }
        
              .cls-13 {
                fill: url(#radial-gradient-13);
              }
        
              .cls-14 {
                fill: url(#radial-gradient-10);
              }
        
              .cls-15 {
                fill: url(#radial-gradient-17);
              }
        
              .cls-16 {
                fill: url(#radial-gradient-19);
              }
        
              .cls-17 {
                fill: url(#radial-gradient-11);
              }
        
              .cls-18 {
                fill: url(#radial-gradient-18);
              }
        
              .cls-19 {
                fill: url(#radial-gradient-15);
              }
        
              .cls-20 {
                fill: url(#radial-gradient-20);
              }
        
              .cls-21 {
                fill: url(#radial-gradient-21);
              }
        
              .cls-22 {
                fill: #fff;
              }
            </style>
            <radialGradient id="radial-gradient" cx="242.4" cy="316.3" fx="242.4" fy="316.3" r=".7" gradientTransform="translate(158890.2 -239221.7) rotate(-169) scale(454.9 -839.1)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#eb7031"/>
              <stop offset=".5" stop-color="#bf3da2"/>
              <stop offset="1" stop-color="#80fafd"/>
            </radialGradient>
            <radialGradient id="radial-gradient-2" cx="242.4" cy="316.3" fx="242.4" fy="316.3" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-3" cy="316.3" fy="316.3" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-4" cx="242.4" cy="316.3" fx="242.4" fy="316.3" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-5" cx="242.4" cy="316.3" fx="242.4" fy="316.3" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-6" cx="242.4" cy="316.3" fx="242.4" fy="316.3" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-7" cx="242.4" cy="316.3" fx="242.4" fy="316.3" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-8" cx="242.4" cy="316.3" fx="242.4" fy="316.3" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-9" cx="241.8" cy="316.2" fx="241.8" fy="316.2" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-10" cx="241.8" cy="316.2" fx="241.8" fy="316.2" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-11" cx="241.8" cy="316.2" fx="241.8" fy="316.2" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-12" cx="241.8" cy="316.2" fx="241.8" fy="316.2" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-13" cx="241.8" cy="316.2" fx="241.8" fy="316.2" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-14" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-15" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-16" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-17" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-18" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-19" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-20" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
            <radialGradient id="radial-gradient-21" cx="242.4" cy="316.4" fx="242.4" fy="316.4" r=".7" xlink:href="#radial-gradient"/>
          </defs>
          <!-- Generator: Adobe Illustrator 28.6.0, SVG Export Plug-In . SVG Version: 1.2.0 Build 709)  -->
          <g>
            <g id="Layer_1">
              <g>
                <path class="cls-22" d="M2.2,43.4L17.7.9h14.6l15.4,42.5h-13.9l-2.4-7.6h-13.4l-2.4,7.6H2.2ZM20.6,25.7h8.1l-4-12.2-4.1,12.2Z"/>
                <path class="cls-22" d="M65.6,43.4V.9h15.4c4.5,0,8.5.9,11.9,2.7,3.4,1.8,6,4.3,7.9,7.5,1.9,3.2,2.8,6.9,2.8,11.1s-.5,6.1-1.6,8.7c-1.1,2.6-2.6,4.9-4.6,6.7-2,1.9-4.4,3.3-7.1,4.3-2.8,1-5.9,1.5-9.3,1.5h-15.4ZM77.9,32h2.9c1.5,0,2.9-.2,4.1-.6,1.2-.4,2.3-1,3.2-1.8.9-.8,1.6-1.9,2.1-3.1.5-1.2.7-2.7.7-4.3s-.4-4.1-1.3-5.5c-.9-1.5-2-2.5-3.6-3.3-1.5-.7-3.3-1.1-5.3-1.1h-2.9v19.7Z"/>
                <path class="cls-22" d="M110.4,43.4V.9h12.3v42.5h-12.3Z"/>
                <path class="cls-22" d="M153.6,44.4c-3.2,0-6.3-.5-9.2-1.5-2.9-1-5.5-2.5-7.7-4.4-2.2-1.9-4-4.2-5.3-7-1.3-2.8-1.9-5.9-1.9-9.4s.6-6.5,1.9-9.3c1.2-2.7,2.9-5.1,5.1-7,2.2-1.9,4.7-3.4,7.6-4.4,2.9-1,5.9-1.5,9.1-1.5s3.1.1,4.7.3c1.7.2,3.4.6,5.2,1.2,1.8.6,3.6,1.5,5.5,2.6l-5,9.4c-1.4-.9-2.9-1.6-4.4-2-1.5-.4-3.2-.7-5-.7s-3.6.3-5.1.8c-1.5.5-2.8,1.2-3.8,2.2-1,1-1.8,2.2-2.3,3.6-.5,1.4-.8,3-.8,4.7,0,2.6.6,4.7,1.7,6.4,1.1,1.7,2.6,3,4.5,3.8,1.9.8,3.9,1.2,6.1,1.2s1.3,0,2-.1c.7-.1,1.4-.2,2.1-.4v-5.7h-8.1v-9.6h18.7v22.9c-1.4.7-3,1.3-4.8,1.9-1.8.5-3.6.9-5.5,1.2-1.9.3-3.6.4-5.3.4Z"/>
                <path class="cls-22" d="M177.8,43.4V.9h12.3v42.5h-12.3Z"/>
                <path class="cls-22" d="M206.5,43.4V12.1h-10.9V.9h34.2v11.2h-10.9v31.4h-12.3Z"/>
                <path class="cls-22" d="M227.8,43.4L243.3.9h14.6l15.4,42.5h-13.9l-2.4-7.6h-13.4l-2.4,7.6h-13.5ZM246.3,25.7h8.1l-4-12.2-4.1,12.2Z"/>
                <path class="cls-22" d="M277.9,43.4V.9h12.3v31.4h16.1v11.2h-28.4Z"/>
                <path class="cls-22" d="M5.3,106.7v-42.5h18.2c3.3,0,6.2.6,8.7,1.7,2.6,1.2,4.6,2.8,6,5,1.5,2.2,2.2,4.8,2.2,7.9s-.7,5.8-2,8c-1.4,2.2-3.3,3.9-5.9,5-2.6,1.2-5.7,1.7-9.3,1.7h-5.6v13.1H5.3ZM17.6,83.4h5.1c1.7,0,3-.3,4-.9,1-.6,1.5-1.8,1.5-3.4s-.5-2.9-1.5-3.5c-1-.6-2.3-.9-3.9-.9h-5.2v8.8Z"/>
                <path class="cls-22" d="M39.7,106.7l15.5-42.5h14.6l15.4,42.5h-13.9l-2.4-7.6h-13.4l-2.4,7.6h-13.5ZM58.1,89h8.1l-4-12.2-4.1,12.2Z"/>
                <path class="cls-22" d="M89.8,106.7v-42.5h18.1c3.3,0,6.2.5,8.7,1.5,2.5,1,4.4,2.5,5.8,4.5,1.4,2,2.1,4.4,2.1,7.2s-.4,3.9-1.1,5.4c-.7,1.6-1.7,2.9-2.9,3.9-1.2,1.1-2.6,1.9-4,2.5l10.5,17.4h-14.5l-8-14.6h-2.4v14.6h-12.3ZM102,82.4h4.5c1.7,0,3-.3,4-.9,1-.6,1.5-1.7,1.5-3.2s-.5-2.6-1.5-3.2c-1-.6-2.2-.9-3.9-.9h-4.6v8.3Z"/>
                <path class="cls-22" d="M139.5,106.7v-31.4h-10.9v-11.2h34.2v11.2h-10.9v31.4h-12.3Z"/>
                <path class="cls-22" d="M168.3,106.7v-42.5h12.3l14.9,22.8v-22.8h11.9v42.5h-12.3l-14.9-23.2v23.2h-11.9Z"/>
                <path class="cls-22" d="M216.6,106.7v-42.5h31.6v10.6h-19.3v5.5h15.8v9.7h-15.8v6.1h19.5v10.7h-31.8Z"/>
                <path class="cls-22" d="M256.2,106.7v-42.5h18.1c3.3,0,6.2.5,8.7,1.5,2.5,1,4.4,2.5,5.8,4.5,1.4,2,2.1,4.4,2.1,7.2s-.4,3.9-1.1,5.4c-.7,1.6-1.7,2.9-2.9,3.9-1.2,1.1-2.6,1.9-4,2.5l10.5,17.4h-14.5l-8-14.6h-2.4v14.6h-12.3ZM268.4,82.4h4.5c1.7,0,3-.3,4-.9,1-.6,1.5-1.7,1.5-3.2s-.5-2.6-1.5-3.2c-1-.6-2.2-.9-3.9-.9h-4.6v8.3Z"/>
                <path class="cls-22" d="M320.3,106.7v-31.4h-10.9v-11.2h34.2v11.2h-10.9v31.4h-12.3Z"/>
                <path class="cls-22" d="M368.1,107.6c-3.3,0-6.4-.6-9.2-1.7-2.8-1.1-5.2-2.7-7.3-4.7-2-2-3.6-4.4-4.8-7-1.1-2.7-1.7-5.6-1.7-8.8s.6-6.1,1.7-8.8c1.1-2.7,2.7-5,4.8-7,2.1-2,4.5-3.6,7.3-4.7,2.8-1.1,5.9-1.7,9.2-1.7s6.4.6,9.1,1.7c2.8,1.1,5.2,2.7,7.3,4.7,2.1,2,3.7,4.4,4.8,7.1,1.1,2.7,1.7,5.6,1.7,8.7s-.6,6.1-1.7,8.8c-1.1,2.7-2.7,5-4.8,7-2,2-4.5,3.6-7.3,4.7-2.8,1.1-5.8,1.7-9.1,1.7ZM368.1,96.1c1.5,0,2.8-.2,4-.7,1.2-.5,2.3-1.2,3.2-2.2.9-.9,1.6-2.1,2.1-3.4.5-1.3.7-2.8.7-4.4s-.4-4.1-1.3-5.7c-.9-1.6-2-2.8-3.6-3.7-1.5-.9-3.2-1.3-5.2-1.3s-2.8.3-4.1.8c-1.2.5-2.3,1.2-3.2,2.1-.9.9-1.6,2.1-2.1,3.4-.5,1.3-.7,2.8-.7,4.4s.4,4.1,1.3,5.7,2,2.8,3.5,3.7c1.5.9,3.3,1.3,5.2,1.3Z"/>
                <path class="cls-22" d="M27.9,171.8c-3.2,0-6.3-.5-9.2-1.5-2.9-1-5.5-2.5-7.7-4.4-2.2-1.9-4-4.2-5.3-7-1.3-2.8-1.9-5.9-1.9-9.4s.6-6.5,1.9-9.3c1.2-2.7,2.9-5.1,5.1-7,2.2-1.9,4.7-3.4,7.6-4.4,2.9-1,5.9-1.5,9.1-1.5s3.1.1,4.7.3c1.7.2,3.4.6,5.2,1.2,1.8.6,3.6,1.5,5.5,2.6l-5,9.4c-1.4-.9-2.9-1.6-4.4-2-1.5-.4-3.2-.7-5-.7s-3.6.3-5.1.8c-1.5.5-2.8,1.2-3.8,2.2-1,1-1.8,2.2-2.3,3.6-.5,1.4-.8,3-.8,4.7,0,2.6.6,4.7,1.7,6.4,1.1,1.7,2.6,3,4.5,3.8,1.9.8,3.9,1.2,6.1,1.2s1.3,0,2-.1c.7-.1,1.4-.2,2.1-.4v-5.7h-8.1v-9.6h18.7v22.9c-1.4.7-3,1.3-4.8,1.9-1.8.5-3.6.9-5.5,1.2-1.9.3-3.6.4-5.3.4Z"/>
                <path class="cls-22" d="M52.1,170.8v-42.5h18.1c3.3,0,6.2.5,8.7,1.5,2.5,1,4.4,2.5,5.8,4.5,1.4,2,2.1,4.4,2.1,7.2s-.4,3.9-1.1,5.4c-.7,1.6-1.7,2.9-2.9,4-1.2,1.1-2.6,1.9-4,2.5l10.5,17.4h-14.5l-8-14.6h-2.4v14.6h-12.3ZM64.4,146.5h4.5c1.7,0,3-.3,4-.9,1-.6,1.5-1.7,1.5-3.2s-.5-2.6-1.5-3.2c-1-.6-2.2-.9-3.9-.9h-4.6v8.3Z"/>
                <path class="cls-22" d="M114.5,171.8c-3.3,0-6.4-.6-9.2-1.7-2.8-1.1-5.2-2.7-7.3-4.7-2-2-3.6-4.4-4.8-7-1.1-2.7-1.7-5.6-1.7-8.8s.6-6.1,1.7-8.8c1.1-2.7,2.7-5,4.8-7,2.1-2,4.5-3.6,7.3-4.7,2.8-1.1,5.9-1.7,9.2-1.7s6.4.6,9.1,1.7c2.8,1.1,5.2,2.7,7.3,4.7,2.1,2,3.7,4.4,4.8,7.1,1.1,2.7,1.7,5.6,1.7,8.7s-.6,6.1-1.7,8.8c-1.1,2.7-2.7,5-4.8,7-2,2-4.5,3.6-7.3,4.7-2.8,1.1-5.8,1.7-9.1,1.7ZM114.5,160.3c1.5,0,2.8-.2,4-.7,1.2-.5,2.3-1.2,3.2-2.2.9-.9,1.6-2.1,2.1-3.4.5-1.3.7-2.8.7-4.4s-.4-4.1-1.3-5.7c-.9-1.6-2-2.8-3.6-3.7-1.5-.9-3.2-1.3-5.2-1.3s-2.8.3-4.1.8c-1.2.5-2.3,1.2-3.2,2.1s-1.6,2.1-2.1,3.4c-.5,1.3-.7,2.8-.7,4.4s.4,4.1,1.3,5.7c.9,1.6,2,2.8,3.5,3.7,1.5.9,3.3,1.3,5.2,1.3Z"/>
                <path class="cls-22" d="M152.1,170.8l-13-42.5h12.6l6.1,23.8,6.7-23.8h12.1l6.7,23.8,6.1-23.8h12.3l-13,42.5h-11.7l-6.7-24.5-6.7,24.5h-11.6Z"/>
                <path class="cls-22" d="M229.8,170.8v-17.4l-14.8-25.2h13.7l7.5,13.4,7.5-13.4h13.3l-14.8,25.3v17.3h-12.3Z"/>
                <path class="cls-22" d="M278.8,171.8c-3.3,0-6.4-.6-9.2-1.7-2.8-1.1-5.2-2.7-7.3-4.7-2-2-3.6-4.4-4.8-7-1.1-2.7-1.7-5.6-1.7-8.8s.6-6.1,1.7-8.8c1.1-2.7,2.7-5,4.8-7,2.1-2,4.5-3.6,7.3-4.7,2.8-1.1,5.9-1.7,9.2-1.7s6.4.6,9.1,1.7c2.8,1.1,5.2,2.7,7.3,4.7,2.1,2,3.7,4.4,4.8,7.1,1.1,2.7,1.7,5.6,1.7,8.7s-.6,6.1-1.7,8.8c-1.1,2.7-2.7,5-4.8,7-2,2-4.5,3.6-7.3,4.7-2.8,1.1-5.8,1.7-9.1,1.7ZM278.8,160.3c1.5,0,2.8-.2,4-.7,1.2-.5,2.3-1.2,3.2-2.2.9-.9,1.6-2.1,2.1-3.4.5-1.3.7-2.8.7-4.4s-.4-4.1-1.3-5.7c-.9-1.6-2-2.8-3.6-3.7-1.5-.9-3.2-1.3-5.2-1.3s-2.8.3-4.1.8c-1.2.5-2.3,1.2-3.2,2.1-.9.9-1.6,2.1-2.1,3.4-.5,1.3-.7,2.8-.7,4.4s.4,4.1,1.3,5.7c.9,1.6,2,2.8,3.5,3.7,1.5.9,3.3,1.3,5.2,1.3Z"/>
                <path class="cls-22" d="M327.2,171.8c-4.1,0-7.6-.8-10.4-2.4-2.8-1.6-4.9-3.8-6.4-6.7s-2.2-6.2-2.2-9.9v-24.4h12.3v25.1c0,2.3.6,4.1,1.7,5.2,1.2,1.1,2.8,1.7,4.9,1.7s3.7-.6,4.9-1.7c1.1-1.1,1.7-2.9,1.7-5.2v-25.1h12.3v24.4c0,3.7-.7,7-2.2,9.9-1.4,2.9-3.6,5.1-6.4,6.7-2.8,1.6-6.3,2.4-10.4,2.4Z"/>
                <path class="cls-22" d="M355.1,170.8v-42.5h18.1c3.3,0,6.2.5,8.7,1.5,2.5,1,4.4,2.5,5.8,4.5,1.4,2,2.1,4.4,2.1,7.2s-.4,3.9-1.1,5.4c-.7,1.6-1.7,2.9-2.9,4-1.2,1.1-2.6,1.9-4,2.5l10.5,17.4h-14.5l-8-14.6h-2.4v14.6h-12.3ZM367.4,146.5h4.5c1.7,0,3-.3,4-.9,1-.6,1.5-1.7,1.5-3.2s-.5-2.6-1.5-3.2c-1-.6-2.2-.9-3.9-.9h-4.6v8.3Z"/>
                <path class="cls-22" d="M27.2,235.7c-3.3,0-6.4-.6-9.2-1.7-2.8-1.1-5.2-2.7-7.3-4.7-2-2-3.6-4.4-4.8-7-1.1-2.7-1.7-5.6-1.7-8.8s.6-6.1,1.7-8.8c1.1-2.7,2.7-5,4.8-7,2.1-2,4.5-3.6,7.3-4.7,2.8-1.1,5.9-1.7,9.2-1.7s6.4.6,9.1,1.7c2.8,1.1,5.2,2.7,7.3,4.7,2.1,2,3.7,4.4,4.8,7.1,1.1,2.7,1.7,5.6,1.7,8.7s-.6,6.1-1.7,8.8c-1.1,2.7-2.7,5-4.8,7-2,2-4.5,3.6-7.3,4.7-2.8,1.1-5.8,1.7-9.1,1.7ZM27.2,224.2c1.5,0,2.8-.2,4-.7,1.2-.5,2.3-1.2,3.2-2.2.9-.9,1.6-2.1,2.1-3.4.5-1.3.7-2.8.7-4.4s-.4-4.1-1.3-5.7c-.9-1.6-2-2.8-3.6-3.7-1.5-.9-3.2-1.3-5.2-1.3s-2.8.3-4.1.8c-1.2.5-2.3,1.2-3.2,2.1s-1.6,2.1-2.1,3.4c-.5,1.3-.7,2.8-.7,4.4s.4,4.1,1.3,5.7c.9,1.6,2,2.8,3.5,3.7,1.5.9,3.3,1.3,5.2,1.3Z"/>
                <path class="cls-22" d="M56.8,234.8v-42.5h12.3l14.9,22.8v-22.8h11.9v42.5h-12.3l-14.9-23.2v23.2h-11.9Z"/>
                <path class="cls-22" d="M105.2,234.8v-42.5h12.3v31.4h16.1v11.2h-28.4Z"/>
                <path class="cls-22" d="M139.9,234.8v-42.5h12.3v42.5h-12.3Z"/>
                <path class="cls-22" d="M161.6,234.8v-42.5h12.3l14.9,22.8v-22.8h11.9v42.5h-12.3l-14.9-23.2v23.2h-11.9Z"/>
                <path class="cls-22" d="M209.9,234.8v-42.5h31.6v10.6h-19.3v5.5h15.8v9.7h-15.8v6.1h19.5v10.7h-31.8Z"/>
                <path class="cls-9" d="M4.4,299.3l9-42.5h15.5c4.5,0,7.9.9,10.4,2.6,2.5,1.7,3.7,4.2,3.7,7.3s-.3,3-1,4.3c-.7,1.3-1.6,2.3-2.9,3.3-1.2.9-2.6,1.7-4.2,2.2,1.8.9,3.2,2.1,4.2,3.8,1,1.6,1.4,3.4,1.4,5.5s-.8,5.3-2.3,7.3c-1.5,2-3.6,3.6-6.1,4.7-2.6,1.1-5.4,1.6-8.5,1.6H4.4ZM17.3,291.2h5.7c1.8,0,3.3-.5,4.3-1.5,1.1-1,1.6-2.3,1.6-4s-.5-2.4-1.4-3.1c-.9-.7-2.2-1.1-4-1.1h-4.1l-2.1,9.7ZM21,274h5.6c1.1,0,2-.2,2.8-.7.8-.5,1.4-1.1,1.9-1.9.4-.8.7-1.6.7-2.6,0-1.3-.5-2.3-1.4-2.9-.9-.6-2.3-1-4.1-1h-3.4l-2,9.1Z"/>
                <path class="cls-8" d="M65,300.2c-4,0-7.4-.7-10.1-2.2-2.7-1.5-4.6-3.6-5.7-6.4-1.1-2.8-1.3-6.1-.4-10l5.3-24.7h11.2l-5.4,25.2c-.5,2.5-.3,4.5.7,5.8,1,1.3,2.8,1.9,5.4,1.9s4-.6,5.5-1.8c1.5-1.2,2.5-3.2,3.1-5.9l5.4-25.2h11.2l-5.2,24.7c-.8,3.8-2.1,7.2-4,10-1.8,2.8-4.2,5-7,6.5-2.8,1.5-6.1,2.3-9.9,2.3Z"/>
                <path class="cls-7" d="M108.3,300.2c-3.5,0-6.9-.6-10.1-1.7-3.2-1.2-5.8-2.8-7.8-4.8l6.6-7.8c.8,1,1.9,1.8,3.3,2.6,1.4.7,2.9,1.3,4.4,1.8,1.6.4,3.1.6,4.6.6s3.2-.3,4.3-.9c1.1-.6,1.7-1.4,1.7-2.6s-.4-1.6-1.2-2.2c-.8-.7-2.2-1.4-4.1-2.1l-3.1-1.1c-3.6-1.3-6.2-2.9-7.8-4.8-1.6-1.9-2.4-4.1-2.4-6.5s.5-4.2,1.4-6c1-1.8,2.2-3.4,3.9-4.7,1.6-1.3,3.5-2.3,5.6-3,2.1-.7,4.3-1.1,6.7-1.1,3.5,0,6.8.6,9.7,1.7,2.9,1.1,5.2,2.6,6.8,4.4l-6.8,7.6c-.7-.9-1.5-1.6-2.6-2.2-1.1-.7-2.3-1.2-3.6-1.5-1.3-.4-2.7-.6-4-.6s-2.2.2-3.1.5c-.9.3-1.6.7-2.1,1.3-.5.5-.8,1.2-.8,1.9s.2,1,.5,1.4c.3.4.9.8,1.6,1.2.8.4,1.8.8,3.1,1.3l3.3,1.2c3.6,1.4,6.2,2.9,7.9,4.7,1.7,1.8,2.5,3.9,2.5,6.4s-.8,5.7-2.5,8c-1.6,2.2-3.8,4-6.6,5.2-2.7,1.2-5.8,1.9-9.1,1.9Z"/>
                <path class="cls-4" d="M130.4,299.3l9-42.5h11.2l-9,42.5h-11.2Z"/>
                <path class="cls-3" d="M151.4,299.3l9-42.5h11l11,24.9,5.3-24.9h10.8l-9,42.5h-10.9l-11-25.2-5.4,25.2h-10.8Z"/>
                <path class="cls-2" d="M199.3,299.3l9-42.5h30.7l-2,9.7h-19.6l-1.4,6.7h16.2l-1.9,8.9h-16.2l-1.6,7.5h19.8l-2,9.8h-31Z"/>
                <path class="cls-1" d="M255.1,300.2c-3.5,0-6.9-.6-10.1-1.7-3.2-1.2-5.8-2.8-7.8-4.8l6.6-7.8c.8,1,1.9,1.8,3.3,2.6,1.4.7,2.9,1.3,4.4,1.8,1.6.4,3.1.6,4.6.6s3.2-.3,4.3-.9c1.1-.6,1.7-1.4,1.7-2.6s-.4-1.6-1.2-2.2c-.8-.7-2.2-1.4-4.1-2.1l-3.1-1.1c-3.6-1.3-6.2-2.9-7.8-4.8-1.6-1.9-2.4-4.1-2.4-6.5s.5-4.2,1.4-6c1-1.8,2.2-3.4,3.9-4.7,1.6-1.3,3.5-2.3,5.6-3,2.1-.7,4.3-1.1,6.7-1.1,3.5,0,6.8.6,9.7,1.7,2.9,1.1,5.2,2.6,6.8,4.4l-6.8,7.6c-.7-.9-1.5-1.6-2.6-2.2-1.1-.7-2.3-1.2-3.6-1.5-1.3-.4-2.7-.6-4-.6s-2.2.2-3.1.5c-.9.3-1.6.7-2.1,1.3-.5.5-.8,1.2-.8,1.9s.2,1,.5,1.4c.3.4.9.8,1.6,1.2.8.4,1.8.8,3.1,1.3l3.3,1.2c3.6,1.4,6.2,2.9,7.9,4.7,1.7,1.8,2.5,3.9,2.5,6.4s-.8,5.7-2.5,8c-1.6,2.2-3.8,4-6.6,5.2-2.7,1.2-5.8,1.9-9.1,1.9Z"/>
                <path class="cls-6" d="M293.9,300.2c-3.5,0-6.9-.6-10.1-1.7-3.2-1.2-5.8-2.8-7.8-4.8l6.6-7.8c.8,1,1.9,1.8,3.3,2.6,1.4.7,2.9,1.3,4.4,1.8,1.6.4,3.1.6,4.6.6s3.2-.3,4.3-.9c1.1-.6,1.7-1.4,1.7-2.6s-.4-1.6-1.2-2.2c-.8-.7-2.2-1.4-4.1-2.1l-3.1-1.1c-3.6-1.3-6.2-2.9-7.8-4.8-1.6-1.9-2.4-4.1-2.4-6.5s.5-4.2,1.4-6c1-1.8,2.2-3.4,3.9-4.7,1.6-1.3,3.5-2.3,5.6-3,2.1-.7,4.3-1.1,6.7-1.1,3.5,0,6.8.6,9.7,1.7,2.9,1.1,5.2,2.6,6.8,4.4l-6.8,7.6c-.7-.9-1.6-1.6-2.6-2.2-1.1-.7-2.3-1.2-3.6-1.5-1.3-.4-2.7-.6-4-.6s-2.2.2-3.1.5c-.9.3-1.6.7-2.1,1.3-.5.5-.8,1.2-.8,1.9s.2,1,.5,1.4c.3.4.9.8,1.6,1.2.8.4,1.8.8,3.1,1.3l3.3,1.2c3.6,1.4,6.2,2.9,7.9,4.7,1.7,1.8,2.5,3.9,2.5,6.4s-.8,5.7-2.5,8c-1.6,2.2-3.8,4-6.6,5.2-2.7,1.2-5.8,1.9-9.1,1.9Z"/>
                <path class="cls-5" d="M3.7,361.2l9-42.5h15.5c4.5,0,7.9.9,10.4,2.6,2.5,1.7,3.7,4.2,3.7,7.3s-.3,3-1,4.3c-.7,1.3-1.6,2.4-2.9,3.3-1.2.9-2.6,1.7-4.2,2.2,1.8.9,3.2,2.1,4.2,3.8,1,1.6,1.4,3.4,1.4,5.5s-.8,5.3-2.3,7.3c-1.5,2-3.6,3.6-6.1,4.7-2.6,1.1-5.4,1.6-8.5,1.6H3.7ZM16.6,353.1h5.7c1.8,0,3.3-.5,4.3-1.5,1.1-1,1.6-2.3,1.6-4s-.5-2.4-1.4-3.1c-.9-.7-2.2-1.1-4-1.1h-4.1l-2.1,9.7ZM20.3,335.9h5.6c1.1,0,2-.2,2.8-.7.8-.5,1.4-1.1,1.9-1.9.4-.8.7-1.6.7-2.6,0-1.3-.5-2.3-1.4-2.9-.9-.6-2.3-1-4.1-1h-3.4l-2,9.1Z"/>
                <path class="cls-14" d="M44.5,361.2l9-42.5h15.7c3.3,0,6.1.5,8.4,1.4,2.3.9,4.1,2.3,5.3,4,1.2,1.7,1.9,3.8,1.9,6.2s-.5,4.3-1.5,6.2c-1,1.9-2.4,3.5-4.1,4.8-1.7,1.3-3.7,2.2-6,2.8l7.6,17h-12.8l-6-15.2h-3.1l-3.2,15.2h-11.2ZM60.7,337.2h6.4c1.8,0,3.3-.5,4.3-1.4,1.1-.9,1.7-2.2,1.7-4s-.5-2.6-1.6-3.2c-1.1-.6-2.6-.9-4.5-.9h-4.3l-2,9.4Z"/>
                <path class="cls-17" d="M81.9,361.2l25-42.5h13.1l6.6,42.5h-12.3l-1-8.1h-14.1l-4.4,8.1h-12.8ZM103.6,343.8h9.2l-1.8-13.2-7.4,13.2Z"/>
                <path class="cls-11" d="M131.6,361.2l9-42.5h11l11,24.9,5.3-24.9h10.8l-9,42.5h-10.9l-11-25.2-5.4,25.2h-10.8Z"/>
                <path class="cls-13" d="M179.5,361.2l9-42.5h11.4c4.2,0,8,.7,11.3,2.2,3.3,1.4,5.9,3.5,7.7,6.3,1.9,2.8,2.8,6.1,2.8,10.1s-.6,6.7-1.8,9.6c-1.2,2.9-2.9,5.5-5.1,7.6-2.2,2.1-4.8,3.8-7.8,5-3,1.2-6.3,1.8-9.9,1.8h-17.6ZM192.9,350.8h4.9c1.9,0,3.6-.3,5-.9,1.5-.6,2.8-1.5,3.8-2.6,1.1-1.1,1.9-2.5,2.4-4,.6-1.5.9-3.2.9-5s-.4-3.7-1.3-5c-.9-1.4-2.1-2.4-3.7-3.1-1.6-.7-3.4-1-5.5-1h-2l-4.6,21.7Z"/>
                <path class="cls-12" d="M19.1,428.2c-3.5,0-6.9-.6-10.1-1.7-3.2-1.2-5.8-2.8-7.8-4.8l6.6-7.8c.8,1,1.9,1.8,3.3,2.6,1.4.7,2.9,1.3,4.4,1.8,1.6.4,3.1.6,4.6.6s3.2-.3,4.3-.9c1.1-.6,1.7-1.4,1.7-2.6s-.4-1.6-1.2-2.2c-.8-.7-2.2-1.4-4.1-2.1l-3.1-1.1c-3.6-1.3-6.2-2.9-7.8-4.8-1.6-1.9-2.4-4.1-2.4-6.5s.5-4.2,1.4-6c1-1.8,2.2-3.4,3.9-4.7,1.6-1.3,3.5-2.3,5.6-3,2.1-.7,4.3-1.1,6.7-1.1,3.5,0,6.8.6,9.7,1.7,2.9,1.1,5.2,2.6,6.8,4.4l-6.8,7.6c-.7-.9-1.5-1.6-2.6-2.2-1.1-.7-2.3-1.2-3.6-1.5-1.3-.4-2.7-.6-4-.6s-2.2.2-3.1.5c-.9.3-1.6.7-2.1,1.3-.5.5-.8,1.2-.8,1.9s.2,1,.5,1.4c.3.4.9.8,1.6,1.2.8.4,1.8.8,3.1,1.3l3.3,1.2c3.6,1.4,6.2,2.9,7.9,4.7,1.7,1.8,2.5,3.9,2.5,6.4s-.8,5.7-2.5,8c-1.6,2.2-3.8,4-6.6,5.2-2.7,1.2-5.8,1.9-9.1,1.9Z"/>
                <path class="cls-19" d="M48.6,427.3l6.8-32.4h-11.2l2.2-10.2h33.6l-2.2,10.2h-11.2l-6.8,32.4h-11.2Z"/>
                <path class="cls-10" d="M76.8,427.3l9-42.5h15.7c3.3,0,6.1.5,8.4,1.4,2.3.9,4.1,2.3,5.3,4,1.2,1.7,1.9,3.8,1.9,6.2s-.5,4.3-1.5,6.2c-1,1.9-2.4,3.5-4.1,4.8-1.7,1.3-3.7,2.2-6,2.8l7.6,17h-12.8l-6-15.2h-3.1l-3.2,15.2h-11.2ZM93.1,403.3h6.4c1.8,0,3.3-.5,4.3-1.4,1.1-.9,1.7-2.2,1.7-4s-.5-2.6-1.6-3.2c-1.1-.6-2.6-.9-4.5-.9h-4.3l-2,9.4Z"/>
                <path class="cls-15" d="M114.3,427.3l25-42.5h13.1l6.6,42.5h-12.3l-1-8.1h-14.1l-4.4,8.1h-12.8ZM135.9,409.9h9.2l-1.8-13.2-7.4,13.2Z"/>
                <path class="cls-18" d="M167.7,427.3l6.8-32.4h-11.2l2.2-10.2h33.6l-2.2,10.2h-11.2l-6.8,32.4h-11.2Z"/>
                <path class="cls-16" d="M195.9,427.3l9-42.5h30.7l-2,9.7h-19.6l-1.4,6.7h16.2l-1.9,8.9h-16.2l-1.6,7.5h19.8l-2,9.8h-31Z"/>
                <path class="cls-20" d="M259.1,428.2c-3.1,0-6-.4-8.7-1.3-2.7-.9-5-2.2-7.1-3.9-2-1.7-3.6-3.8-4.7-6.2-1.1-2.4-1.7-5.1-1.7-8.2s.7-6.8,2-9.8c1.3-3,3.2-5.6,5.5-7.9,2.4-2.2,5.1-4,8.2-5.2,3.1-1.2,6.5-1.9,10.1-1.9s3.4.1,5.2.4c1.8.3,3.7.8,5.6,1.5,1.9.7,3.7,1.8,5.5,3.3l-6.2,8.1c-1.4-1.1-2.9-1.9-4.7-2.5-1.7-.5-3.6-.8-5.7-.8s-4.1.3-5.8,1c-1.7.7-3.2,1.7-4.4,2.9-1.2,1.3-2.1,2.7-2.8,4.4-.6,1.7-1,3.6-1,5.6s.5,4.3,1.6,5.8c1.1,1.6,2.6,2.7,4.4,3.6,1.9.8,4,1.2,6.3,1.2s1.2,0,1.7,0c.6,0,1.1-.1,1.7-.2l1.5-6.9h-8.4l1.9-8.8h18.1l-5,23.5c-1.3.5-2.6.9-4.2,1.2-1.5.3-3,.6-4.6.7-1.5.2-3,.3-4.4.3Z"/>
                <path class="cls-21" d="M289.2,427.3l3.7-17.6-9.2-24.9h12l4.9,14.1,11.1-14.1h12.8l-20.3,25.2-3.7,17.4h-11.2Z"/>
                <path class="cls-22" d="M254,362.1c-2.7,0-5-.5-7-1.5-2-1-3.5-2.3-4.6-4-1.1-1.7-1.6-3.7-1.6-5.9s.5-4.2,1.4-6c1-1.8,2.3-3.4,3.9-4.8,1.7-1.4,3.6-2.5,5.7-3.3-.5-.8-.9-1.5-1.2-2.3-.3-.7-.5-1.4-.7-2.2-.1-.7-.2-1.5-.2-2.2,0-2.3.6-4.4,1.9-6.2,1.3-1.9,3-3.3,5-4.4,2.1-1.1,4.4-1.6,6.9-1.6s4.3.4,6.1,1.2c1.8.8,3.2,1.9,4.2,3.3,1,1.4,1.5,3.1,1.5,4.9s-.8,4.7-2.5,6.6c-1.6,1.9-4.2,3.7-7.6,5.3l4.4,5.9c1.3-1.4,2.6-2.8,3.7-4.4,1.1-1.6,2.3-3.6,3.4-6l8.2,3.4c-.7,1.4-1.6,3-2.8,4.7-1.2,1.7-2.5,3.4-3.9,5s-2.7,3-4,4.1l6.7,9.5h-11.6l-2.5-3.4c-1.9,1.4-3.9,2.4-6.2,3.2-2.3.7-4.5,1.1-6.6,1.1ZM255.8,353.3c1.1,0,2.1-.2,3.2-.6,1.1-.4,2.1-.9,2.9-1.6l-5.9-8.1c-1.5.5-2.8,1.4-3.8,2.6-1,1.2-1.5,2.4-1.5,3.7s.5,2.2,1.4,2.9c.9.7,2.2,1.1,3.7,1.1ZM260.7,333.6c1.7-.5,3-1.2,4-2,1-.9,1.5-1.9,1.5-3s-.3-1.7-.9-2.2c-.6-.6-1.4-.9-2.5-.9s-2.1.3-2.9,1c-.8.7-1.2,1.6-1.2,2.7s.2,1.3.5,2.1c.3.8.8,1.5,1.5,2.3Z"/>
                <path class="cls-22" d="M322.5,310.8l-2.2-4.5c2.2-.9,4.1-1.9,5.8-3,1.7-1.1,3-2.3,3.8-3.6-1.2,0-2.2-.3-3.1-.8-.9-.5-1.6-1.1-2.2-1.9-.5-.8-.8-1.8-.8-2.8,0-1.9.7-3.5,2-4.7,1.3-1.3,3-1.9,4.9-1.9s3.7.6,4.9,1.8c1.2,1.2,1.9,2.8,1.9,4.9s-.7,4.6-2.1,6.6c-1.4,2-3.2,3.9-5.5,5.5-2.3,1.6-4.7,3.1-7.4,4.4Z"/>
              </g>
            </g>
          </g>
        </svg>
          
  </div>

  
</div>

<div class="page-content">
  <div class="page-wrapper column-wrapper">

    <!-- <h2>Your digital partner for business growth</h2>
    <p>
      At Bouncd, we don't just build websites; we become your digital partner, helping to grow your online business, brand, and strategy. By creating fast, modern, and reliable websites, we reduce bounce rates and ensure your visitors stay engaged.
    </p>

    <h2>What is a bounce rate?</h2>
      <p>
        Bounce rate is the percentage of visitors who leave your site after viewing just one page. It's a critical metric that can signal potential issues like slow load times, poor user experience, or irrelevant content. A high bounce rate means lost opportunities. But don't worry—Bouncd is here to help.
      </p>

    <h2>How bouncd reduces your bounce rate</h2>
    <div class="services-preview">
      <a href="/services/design" class="service-card">
        <h3>1. Speed = Success</h3>
        <p>
          Slow websites lose users. Bouncd builds ultra-fast websites, ensuring they load in less than 3 seconds, so your audience stays engaged and interested.
        </p>
      </a>

      <a href="/services/development" class="service-card">
        <h3>2. Strategic Design for Your Brand</h3>
        <p>
          Bouncd specializes in creating modern, brand-aligned designs that not only look great but provide an intuitive user experience. This keeps visitors on your site, exploring your content and engaging with your brand.
        </p>
      </a>

      <a href="/services/strategy" class="service-card">
        <h3>3. Always Up, Always Reliable</h3>
        <p>
          Downtime kills trust. With our 99.95% uptime guarantee, Bouncd makes sure your website is always online, ready to grow your business by keeping visitors on your pages.
        </p>
      </a>

    </div> -->

    <!-- <h2>A Partnership for Long-Term Growth</h2> -->
    <p class="callout">
      Bouncd goes beyond web design—we partner with you to grow your business, brand, and strategy. From reducing bounce rates to creating engaging, brand-focused experiences, we provide the digital foundation for long-term success.
    </p>

    <div class="services-cta">
      
      <h2>Our Services at a Glance</h2>

      <div class="services-preview">
        <a href="/services/design" class="service-card">
          <h3>Brand Redesign: Transform Your Digital Identity</h3>
          <div class="card-copy">
            <p>
              At Bouncd, we help you rethink and reinvent your brand from the ground up. Our brand redesign services ensure that your online presence aligns with your business goals and resonates with your target audience. 
            </p>

            <p class="arrow-link">
              Learn More
              <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.15886C4.41438 8.15886 8.02587 4.53761 8.02587 0H12.0259V0.0379376C12.0333 4.569 15.6419 8.18281 20.0517 8.18281V12.1828C16.9591 12.1828 14.15 11.0096 12.0259 9.08403L12.0259 26.0067H8.02587L8.02587 9.06007C5.90178 10.9857 3.09268 12.1589 0 12.1589V8.15886Z" />
              </svg>
            </p>
            <!-- Whether you're starting from scratch or refreshing an existing brand, we deliver cohesive and engaging designs that tell your story and elevate your digital footprint. With a modern and creative approach, we bring your vision to life, making your brand unforgettable. -->
          </div>

        </a>
        <a href="/services/development" class="service-card">
          <h3>Digital Engineering: Crafting Future-Ready Platforms</h3>
          <div class="card-copy">
            <p>
              Our digital engineering services are designed to build modern, high-performance web platforms that not only look great but are optimized for speed, scalability, and long-term growth. 
            </p>
            <p class="arrow-link">
              Learn More
              <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.15886C4.41438 8.15886 8.02587 4.53761 8.02587 0H12.0259V0.0379376C12.0333 4.569 15.6419 8.18281 20.0517 8.18281V12.1828C16.9591 12.1828 14.15 11.0096 12.0259 9.08403L12.0259 26.0067H8.02587L8.02587 9.06007C5.90178 10.9857 3.09268 12.1589 0 12.1589V8.15886Z" />
              </svg>
            </p>
            <!-- We deliver engineered solutions tailored to reduce bounce rates and enhance user engagement, ensuring your platform is equipped to handle future demands. From responsive design to cutting-edge backend architecture, Bouncd creates digital ecosystems that are built to evolve with your business. -->
          </div>

        </a>
        <a href="/services/strategy" class="service-card">
          <h3>Enterprise Consulting: Scalable Strategies for Growth</h3>
          <div class="card-copy">
            <p>
              Bouncd's enterprise consulting services provide comprehensive solutions to scale your business. We work with stakeholders to define a technology vision, assess and mitigate risks, and create a solid digital foundation. 
            </p>
            <p class="arrow-link">
              Learn More
              <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.15886C4.41438 8.15886 8.02587 4.53761 8.02587 0H12.0259V0.0379376C12.0333 4.569 15.6419 8.18281 20.0517 8.18281V12.1828C16.9591 12.1828 14.15 11.0096 12.0259 9.08403L12.0259 26.0067H8.02587L8.02587 9.06007C5.90178 10.9857 3.09268 12.1589 0 12.1589V8.15886Z" />
              </svg>
            </p>
            <!-- Our expertise spans enterprise architecture, technology strategy, and product management, focusing on agile delivery, scalability, and continuous improvement. Whether it's modernizing legacy systems or refining your infrastructure, we partner with you to drive sustainable success. -->
          </div>
        </a>
      </div>
    </div>
  </div>
<!-- 
  <div class="full-width-banner magenta-banner">

    <div class="page-wrapper column-wrapper">

      <h2>What is a bounce rate?</h2>
      <p>
        Bounce rate is the percentage of visitors who leave your site after viewing just one page. It's a critical metric that can signal potential issues like slow load times, poor user experience, or irrelevant content. A high bounce rate means lost opportunities. But don't worry—Bouncd is here to help.
      </p>

      <h2>How bouncd reduces your bounce rate</h2>
      <div class="services-preview">
        <a href="/services/design" class="service-card">
          <h3>1. Speed = Success</h3>
          <p>
            Slow websites lose users. Bouncd builds ultra-fast websites, ensuring they load in less than 3 seconds, so your audience stays engaged and interested.
          </p>
        </a>

        <a href="/services/development" class="service-card">
          <h3>2. Strategic Design for Your Brand</h3>
          <p>
            Bouncd specializes in creating modern, brand-aligned designs that not only look great but provide an intuitive user experience. This keeps visitors on your site, exploring your content and engaging with your brand.
          </p>
        </a>

        <a href="/services/strategy" class="service-card">
          <h3>3. Always Up, Always Reliable</h3>
          <p>
            Downtime kills trust. With our 99.95% uptime guarantee, Bouncd makes sure your website is always online, ready to grow your business by keeping visitors on your pages.
          </p>
        </a>

      </div>
    </div>

  </div> -->

</div>
