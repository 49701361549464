import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from '../../auth_config.json';
import { ContactForm } from 'models/ContactForm';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  @Output('failed') failed: EventEmitter<boolean> = new EventEmitter();
  @Output('success') success: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {}

  ping$() {
    return this.http.get(`${config.apiUri}/api/external`);
  }

  mail(contactForm: ContactForm): void {
    this.http
      .post(environment.emailApiURL, JSON.stringify(contactForm))
      .subscribe(
        (data) => {
          // console.log(data);
          this.success.emit();
        },
        (error) => {
          // console.log(error);
          this.failed.emit(error);
        }
      );
  }
}
